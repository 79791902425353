import { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { SessionContext, dvSrv } from "./App";
import ReactJson from "@microlink/react-json-view";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const SSOSignIn = () => {
    useEffect(() => {
        window.location.href = dvSrv+'/api/sso';
      }, []);
    
      return (
        <>
            <div>Redirecting to Microsoft login...</div>
            {/* <ReactJson src={{email, passcode}} /> */}
        </>
      )
}

export const SSOVerify = () => {
    const [errBar, setErrBar] = useState("")
  //  const location = useLocation()
    const session = useContext(SessionContext)
    const navigate = useNavigate()

    const query = useQuery();
    const code = query.get('code');
    const state = query.get('state');
    
    useEffect(() => {
        const verifySSO = async () => {
            axios.post(dvSrv + "/api/sso/verify",
              {
                SSOCode: code,
                State: state
              }, {withCredentials: true})
            .then(({data}) => {
              session.setData(data)
              navigate("/dashboard/")
            })
            .catch((error) => {
              session.setData(null)
              if (error?.response?.status === 401) {
                setErrBar("SSO verification failed. Please make sure the email is a valid user in DeepVin.com.")
              } else { setErrBar("Error: " + error) }
            })
        }
        if (code && state){
          verifySSO()
        }
    }, [])

    return (
      <>
      <p>{errBar && errBar}</p>
      {/* <ReactJson src={{errData}} /> */}
      </>
    )
  }
  