import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import ReactJson from "@microlink/react-json-view"
import { dvSrv } from "../App"
import { SessionContext } from '../App';
import { TeamSelector, UserSelector } from './Team';
import moment from 'moment-timezone';
import DataProvider, { useActiveTemplate, useTeamAndUser } from '../hook/DataProvider';
import { TimeStringForTimeZone } from "./Setting"
import { toast } from "react-toastify"

export const Reports = () => {

  const template = useActiveTemplate("ReportTemplate")
  const data = DataProvider(dvSrv + "/api/report/")

  let total = data.length
  const percent = (id, which) => {
    let sum = 0
    data.forEach(report => {
      report.FieldValues.forEach(v => {
        if (v.FieldID === id && v.Value === which)
          sum += 1
      })
    })
    if (sum > 0) return parseFloat(sum/total*100).toFixed(0)+"%"
  }

  if (data)
  return (
    <>
    <h2>Reports</h2>
    <table class="table table-hover">
      <thead>
        <th>#</th>
        <th class="text-center"></th>
        <th>Description</th>
        <th>Options</th>
        <th class="text-end col-1">1</th>
        <th class="text-end col-1">2</th>
        <th class="text-end col-1">3</th>
        {/* <th>Total Cases</th> */}
        <th>Note</th>
      </thead>
      <tbody>
        {/* {data && data.map(report => <ReportTab report={report} />)} */}

        {template.TemplateFields && template.TemplateFields.map(field => 
        <tr>
          <td>{field.Number}</td>
          <td class="text-center">
            <input class="form-check-input" type="checkbox"
              checked={field.Enabled} />
          </td>
          <td>{field.Description}</td>
          <td>{field.FieldValues}</td>
          <td class="text-end">{percent(field.ID, 1)}</td>
          <td class="text-end">{percent(field.ID, 2)}</td>
          <td class="text-end">{percent(field.ID, 3)}</td>
          <td></td>
        </tr>)}

      </tbody>
    </table>
    {/* <ReactJson src={{template, data}} /> */}
    </>
  )
}

export const ReportTab = ({report}) => {
  const session = useContext(SessionContext)

  if (report && report.FieldValues)
  return (
    <>
    <tr>
      <td></td>
      <td>{report.CaseNumber}</td>
      <td>{TimeStringForTimeZone(session, report.Date)}</td>
      <td>{report.TeamID}</td>
      {report.FieldValues.map(v => 
        <td>
          {v.Value}
        </td>
      )}
    </tr>
    </>
  )
}

// export const AirwayReport = ({caseNumber}) => {
//   const session = useContext(SessionContext)
//   const [report, setReport] = useState()
//   const [teams, users] = useTeamAndUser()

//   useEffect(() => {
//     axios.get(dvSrv + `/api/airwayreport/${caseNumber}`, {withCredentials: true})
//     .then(({data}) => {
//       setReport(data)
//     })
//     .catch(error => {
//       if (error.response && error.response.status === 401) {
//           session.setData(null)
//       } else {console.error("Error: ", error)}
//     })}, [caseNumber])  
    
//   const onSave = (e) => {
//     axios.post(dvSrv + `/api/airwayreport/${caseNumber}`, report, {withCredentials: true})
//     .then(({data}) => {
//       setReport(data)
//     })
//     .catch(error => {
//       if (error.response && error.response.status === 401) {
//           session.setData(null)
//       } else {console.error("Error: ", error)}
//     })}

//   const onValueChange = (e) => {
//     const {name, value} = e.target
//     setReport({...report, [name]: parseInt(value, 10)})
//   }
//   const onCommentsChange = (e) => {
//     const {name, value} = e.target
//     setReport({...report, ["Comments"]: value})
//   }

//   const editor = report && report.UserID && users.find(user => user.ID === report.UserID)

//   if (teams && users && report)
//   return (    
//     <>
//       <div class="row">
//         <div class="col-12">
//           <div class="card mb-3">
//             <h4 class="card-header text-center">Intubation Report</h4>
//             <div class="card-body">
//               <h5 class="card-title text-center mb-3">Case #{caseNumber}</h5>
//               {report.UserID && (users.length > 0) &&
//               <h6 class="card-subtitle text-body-secondary text-center mb-5">
//                 Last edited: {moment(report.UpdatedAt).fromNow()} - by {editor && 
//                 <span>{editor.FirstName} {editor.LastName}</span>}
//               </h6>}
//               <div class="row">
//               <div class="col-6 mb-3">
//                 <label class="fw-bold form-label">Provider</label>
//                 <UserSelector users={users} data={report} setData={setReport} />
//               </div>
              
//               <div class="col-6 mb-3">
//                 <label class="fw-bold form-label">Team</label>
//                 <TeamSelector teams={teams} data={report} setData={setReport} />
//               </div>
//               </div>

//               <h5 class="card-title text-center my-3">Intubation</h5>

//               <RadioButtonGroup title="Type of patient" name="PatientType" value={report.PatientType} onValueChange={onValueChange} labels={["Trauma", "Medical"]} />

//               <RadioButtonGroup title="LMA placed?" name="LMAPlaced" value={report.LMAPlaced} onValueChange={onValueChange} labels={["Yes", "No"]} />

//               <RadioButtonGroup title="Suction utilized?" name="Suction" value={report.Suction} onValueChange={onValueChange} colors={["success","danger","secondary"]} />

//               <RadioButtonGroup title="Blade midline?" name="Midline" value={report.Midline} onValueChange={onValueChange} colors={["success","danger","secondary"]} />

//               <RadioButtonGroup title="Vocal cords visualized?" name="VocalCordsVisualized" value={report.VocalCordsVisualized} onValueChange={onValueChange} colors={["success","danger","secondary"]} />

//               <RadioButtonGroup title="50/50 view?" name="FiftyFiftyView" value={report.FiftyFiftyView} onValueChange={onValueChange} colors={["success","danger","secondary"]} />

//               <RadioButtonGroup title="View maintained for ETT placement?" name="ViewMaintained" value={report.ViewMaintained} onValueChange={onValueChange} labels={["Yes", "No"]} colors={["success","danger"]} />

//               <RadioButtonGroup title="Stylet or Bougie?" name="Stylet" value={report.Stylet} onValueChange={onValueChange} labels={["Stylet", "Bougie"]} />

//               <RadioButtonGroup title="Blade?" name="Blade" value={report.Blade} onValueChange={onValueChange} labels={["Angulated", "Standard"]} />

//               <RadioButtonGroup title="Blade size?" name="BladeSize" value={report.BladeSize} onValueChange={onValueChange} labels={["0", "1", "2", "3", "4"]} />

//               <RadioButtonGroup title="Attempt?" name="Attempt" value={report.Attempt} onValueChange={onValueChange} labels={["1", "2", "3"]} colors={["success", "warning", "danger"]}/>

//               <RadioButtonGroup title="Intubation successful?" name="Successful" value={report.Successful} onValueChange={onValueChange} labels={["Yes", "No"]} colors={["success", "danger"]}/>

//               <div class="form-floating mb-3">
//                 <textarea 
//                   name='review'
//                   id='textinput-review'
//                   class="form-control" 
//                   style={{ height: '200px' }} 
//                   value={report.Comments} 
//                   onChange={onCommentsChange} 
//                   placeholder="Video Review"
//                 />
//                 <label for="textinput-review">Comments</label>
//               </div>
//               <button onClick={onSave} type="submit" class="btn btn-primary">Save</button>
//               {/* <a href="#" class="btn btn-primary">Save</a> */}
//             </div>
//             <div class="card-footer text-muted">
//               Report has been locked. Submit comments here.
//             </div>
//           </div>
//         </div>
//       </div>
//       {/* <ReactJson src={{caseNumber, report, users, teams}} collapsed="0" />     */}
//     </>
//   )
// }

export const RadioButtonGroup = ({title, name, value, onValueChange, labels=["Yes","No","N/A"], colors=["secondary"]}) => {

  return (
    <div class="row mb-3">
      <label class="fw-bold form-label">{title}</label>
      <div class="btn-group col-12">
        {labels.map((label, i) => 
          <>
            <input type="radio" class="btn-check" name={name} id={`${name}-${i+1}`}
              value={i+1} checked={value === i+1} onChange={onValueChange} />
            <label class={`btn btn-outline-${colors.length === 1 ? colors[0] : colors[i]}`}
              for={`${name}-${i+1}`}>{label}</label>
          </>
        )}
      </div>
    </div>
  )
}

// export const CaseReportAndReview = ({casenum, type}) => {

//   return (
//   <>
//     <div class="row">
//       <div class="col-12"> 
//       {/* Field Report */}
//         <CaseReport casenum={casenum}
//                   type = {type}
//                   attUrl={dvSrv + "/api/setting/" + type} 
//                   getpostVideoUrlRoot={dvSrv + "/api/video/"}
//                   getpostCaseUrl={dvSrv + "/api/case/" + casenum +"/" + type}
//                   updateUrl={dvSrv + "/api/" + type + "/"} />
//       </div>
//     </div>
//   </>
//   )
// }

export const RadioButtonGroupForField = ({field, data, onValueChange}) => {

  let val = data ? data.Value : 0

  if(field)
  return (
<>
    <RadioButtonGroupPlus id={field.ID} name={field.FieldName}
                      type={field.FieldType} desciption={field.Description}
                      vals={field.FieldValues} val={val}
                      onValueChange={onValueChange} />
{/* <ReactJson src={{field, data}} collapsed={1} /> */}
</>
)

}

export const RadioButtonGroupPlus = ({id, name, type, desciption, vals, val, onValueChange}) => {

  const onChange = (e) => {
    let v = parseInt(e.target.value, 10)
    onValueChange(id, desciption, labels.join(','), v)
  }

  let labels, colors = ["secondary"]
  
  switch (type) {
    case 'bool':
      labels = ["Yes","No"]
      colors = ["success", "danger"]
      break      
    case 'bool+':
      labels = ["Yes","No", "N/A"]
      colors = ["success", "danger", "secondary"]
      break
    case 'string':
    case 'int':
    default:
      labels = vals.split(',')
      break
  }

  return (
    <>
    <div class="row mb-3">
      <label class="fw-bold form-label">{desciption}</label>
      <div class="btn-group col-12">
        {labels.map((label, i) => 
          <>
          <input 
            type="radio" 
            class="btn-check" 
            name={name} 
            id={name+(i+1)}
            value={i+1}
            checked={val === i+1}
            onChange={onChange} 
          />
          <label class={`btn btn-outline-${colors.length === 1 ? colors[0] : colors[i]}`}
            for={name+(i+1)}>{label}</label>
          </>
        )}
      </div>
    </div>
    {/* <ReactJson src={{field}} collapsed={1} /> */}
    </>
  )
}

export const CaseReport = ({caseNumber}) => {
  const session = useContext(SessionContext)
  const [teams, users] = useTeamAndUser()
  const template = useActiveTemplate("ReportTemplate")
  const [data, setData] = useState({})

  useEffect(() => {
    axios.get(dvSrv + `/api/report/${caseNumber}`, {withCredentials: true})
    .then(({data}) => setData(data))
    .catch(error => {
      if (error.response && error.response.status === 401) {
          session.setData(null)
      } else {console.error("Error: ", error)}
  })}, [caseNumber])

  const onValueChange = (id, desciption, variables, value) => {
    let vs = [...data.FieldValues] // DON'T point to (ie vs = data.FieldValues), MAKE A COPY!!!
    let index = vs.findIndex(v => v.FieldID === id)
    if (index === -1) vs.push({FieldID: id, FieldDescription: desciption, FieldValueList: variables, Value: value}) // new data
    else {
      vs[index].FieldDescription = desciption
      vs[index].FieldValueList = variables
      vs[index].Value = value
    }
    setData({...data, FieldValues: vs})
  }  
  
  const onCommentsChange = (e) => {
    setData({...data, ["Summary"]: e.target.value})
  }

  const onSave = (e) => {
    axios.post(dvSrv + `/api/report/${caseNumber}`, {
      ...data,
      TemplateID: template.ID,
      CaseNumber: caseNumber,
    }, {withCredentials: true})
    .then(({data}) => {
      setData(data)
      toast.success("Report is saved!")
    })
    .catch(error => {
      if (error.response && error.response.status === 401) {
        session.setData(null)
      } else {console.error("Error: ", error)}
    })
  }

  if (template && data)
  return (    
    <>
    <div class="card">
      <h4 class="card-header text-center">Intubation Report</h4>

      <div class="card-body">
        <h5 class="card-title text-center">Case # {caseNumber}</h5>
        {data.UserID ? <p class="card-text">Last modified: {moment(data.UpdatedAt).fromNow()}</p> : ""}
      
        <div class="row mb-3">
          <div class="col-6 mb-3">
            <label class="fw-bold form-label">Provider</label>
            <UserSelector users={users} data={data} setData={setData} />
          </div>      
          <div class="col-6 mb-3">
            <label class="fw-bold form-label">Team</label>
            <TeamSelector teams={teams} data={data} setData={setData} />
          </div>
        </div>
      </div>

      <h5 class="card-title text-center mb-3">QA Measurements</h5>
      
      <div class="card-body">
        {template.TemplateFields && template.TemplateFields.filter(v=>v.Enabled).map(field => 
          <RadioButtonGroupForField field={field} data={data.FieldValues && data.FieldValues.find(v => v.FieldID === field.ID)} onValueChange={onValueChange} />)
        }

        <div class="form-floating">
          <textarea 
            name='review'
            id='textinput-review'
            class="form-control" 
            style={{ height: '200px' }} 
            value={data.Summary} 
            onChange={onCommentsChange} 
            placeholder="Video Review"
          />
          <label for="textinput-review">Comments</label>
        </div>
      </div>
      
      <div class="d-grid gap-2 col-6 mx-auto card-body">
        <button onClick={onSave} type="submit" class="btn btn-primary" disabled={!session.data.ReportEdit}>Save</button>
      </div>

      <div class="card-footer text-muted">
        Report has been locked. Submit new comments as Note below.
      </div>
    </div>
    {/* <ReactJson src={{caseNumber, data, template}} collapsed="3" /> */}
    </>
  )
}

export const CaseReview = ({casenum, type, attUrl, getpostVideoUrlRoot, getpostCaseUrl, updateUrl}) => {
  const [summary, setSummary] = useState("")
  const [FieldValues, setFieldValues] = useState({});
  const [data, setData] = useState({});
  // the review index should be currentReview - 1
  const [currentReview, setCurrentReview] = useState(null)
  const [attributes, setAttributes] = useState([])
  const [changes, setChanges] = useState({});

  const [reviewTypes, setReviewTypes] = useState([]);
  const [selectedReviewType, setSelectedReviewType] = useState(casenum);
  const [videos, setVideos] = useState([])

  const handleSelect = (selectedValue) => {
    // Handle the selected value here
    console.log("Selected option:", selectedValue);
    resetFieldVals({})
    setData({})
    setCurrentReview(null)
    setSummary("")
    setChanges({})
    setSelectedReviewType(selectedValue)

  };

  const resetFieldVals = () =>{
    attributes
    .filter(config => config.Enabled)
    .map(config => ( FieldValues[config.FieldName] = false ))
  }

  useEffect(() => {
    axios.get(attUrl, {withCredentials: true})
    .then(({data}) => {
      setAttributes(data);
    })
    .catch(error => {
      console.error(error);
    })
  }, [attUrl])
  
  useEffect(() => {
    axios.get(dvSrv + `/api/case/${casenum}`, {withCredentials: true})
    .then(({data}) => {
      setVideos(data.Videos)
      reviewTypes.length = 0
      reviewTypes.push(casenum)

      data.Videos.map(v => {
        //if (!reviewTypes.includes(v.FileName)) {
          //setReviewTypes([...reviewTypes, v.FileName]); // Add new option if it doesn't exist
        //  setReviewTypes((prevOptions) => [...prevOptions, v.FileName]);
          reviewTypes.push(v.FileName)
        //}
      })
    })
    .catch(error => {
       console.error("Error: ", error)
    })}, [casenum])
  
  // Fetch reviews from the server on component mount
  useEffect(() => {
    const foundVideo = videos.find(video => video.FileName === selectedReviewType);
    console.log(foundVideo);
    let url = foundVideo ? (getpostVideoUrlRoot + foundVideo.ID +"/" + type) : getpostCaseUrl
    console.log("url: ", url);
    axios.get(url, {withCredentials: true})
    .then(response => 
        {
          setData(response.data)
          if (response.data.result){
              setCurrentReview(response.data.result)
              setSummary(response.data.result.Summary)
          }

          if (response.data.fields && response.data.fields.length > 0){
              for( const val of response.data.values) {
                for (const field of response.data.fields){
                  if(field.ID === val.FieldID){
                    FieldValues[field.FieldName] = (val.Value === "true")
                    break
                }}
              }
              //setFieldValues(tagvals)
          } else { // there is no review or report yet
              resetFieldVals()
          }
        })
    .catch(error => {
      if (error.response.status === 404) {
        //resetTagVals()
      } else { console.error("Error: ", error) }
  })}, [casenum, attributes, selectedReviewType]);

  const handleCheckboxChange = (field, isChecked) => {
    //changes[field] = isChecked
    setChanges(changes => ({
      ...changes,
      [field]: isChecked
    }))
  }

  const onSaveReview = async(e) => {
    e.preventDefault();
    if (changes.length ===0){
        return;
    }
    const formNote = new FormData()
    formNote.append("Summary", summary)    
    for (const [key, value] of Object.entries(changes)) {
    //  console.log(`Key: ${key}, Value: ${value}`);
      formNote.append(key, value)
    }

    if (currentReview === null) { // new review
      const foundVideo = videos.find(video => video.FileName === selectedReviewType);
      let url = foundVideo ? (getpostVideoUrlRoot + foundVideo.ID +"/" + type) : getpostCaseUrl
      axios.post(url, formNote, {withCredentials: true})
        .then(response => {
          //setData(response.data)
          console.log(response.data);
          for( const val of response.data.values) {
            for (const field of response.data.fields){
              if(field.ID === val.FieldID){
                FieldValues[field.FieldName] = (val.Value === "true")
                break
            }}
          }
          //setFieldValues(tagvals)
          setCurrentReview(response.data.result)
          //setSummary(response.data.result.Summary)
          setChanges({})
          toast.success("Review is saved!")
        })
        .catch(error => console.error("Error posting to server: ", error))
    } else if (currentReview !== null) {
      axios.put(updateUrl+`${currentReview.ID}`, formNote, {withCredentials: true})
        .then(response => {
          setChanges({})
          //setReviews(reviews.map(review => review.ID === currentReview.ID ? { ...review, ...response.data } : review));
          for (const [key, value] of Object.entries(response.data)) {
            //  console.log(`Key: ${key}, Value: ${value}`);
              if (key === "Summary") {
                currentReview.Summary = value
              } else{
                FieldValues[key] = (value === "true")  
              }
            }
            //setData(response.data)
            toast.success("Review is saved!")
        })
        .catch(error => console.error("Error saving result: ", error))
    }
  }

  return (
    
    <>
    <div class="row">

        <ReviewTypeSelector options={reviewTypes} onSelect={handleSelect} />

        {attributes.filter(config => config.Enabled).map((config) => (
        <div key={config.FieldName} class="form-check my-2 align-items-center">
          <input 
            class="form-check-input"
            type="checkbox" 
            checked={config.FieldName in changes ? changes[config.FieldName] : FieldValues[config.FieldName]}
            onChange={(e) => handleCheckboxChange(config.FieldName, e.target.checked)}
          />
          <span>{config.Description}</span>
        </div>
      ))}

      <div class="col-12">
        <div class="form-floating">
          <textarea 
            name='review'
            id='textinput-review'
            class="form-control" 
            style={{ height: '400px' }} 
            value={summary} 
            onChange={e => setSummary(e.target.value)} 
            placeholder="Video Review"
          />
          <label for="textinput-review">Summary</label>
        </div>


      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-6">
        <button onClick={onSaveReview} type="submit" class="btn btn-secondary">Save</button>
      </div>
    </div>
    {/* <ReactJson src={{FieldValues}} collapsed="0" />
    <ReactJson src={{changes}} collapsed="0" />
    <ReactJson src={{currentReview}} collapsed="1" />
    <ReactJson src={{data}} collapsed="0" /> */}
    {/* { <ReactJson src={{changes}} collapsed="1" />}
    { <ReactJson src={{FieldValues}} collapsed="1" />}
    {  <ReactJson src={{data}} collapsed="1" />}
    { <ReactJson src={{selectedReviewType}} collapsed="0" />} */}
    
  </>
  )
}


export const TemplateSelector = ({id, teams, data, setData, options, onSelect}) => {

  const handleSelect = (e) => {
    onSelect(parseInt(e.target.value))
  }

  // if (teams)
  // return (
  //   <select class="form-select" name="teamAssigned"
  //     value={data.TeamID || ""}
  //     onChange={handleSelect}>
  //       <option selected>Pick a team</option>
  //       {teams.map(item => <option value={item.ID}>{item.Name}</option>)}
  //   </select>
  // )

  const [selectedOption, setSelectedOption] = useState(options[0])

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedOption(selectedValue);
    onSelect(selectedValue); // Call the callback function with the selected value
  };

  return (
    <div>
      <label class="form-label fw-bold me-3">Select Template:</label>
      <select value={id} onChange={handleSelect}>
        <option selected>Pick a template</option>
        {options.map((option, index) => (
          <option key={index} value={option.ID}>
            {option.Name}
          </option>
        ))}
      </select>
    </div>
  );
}

export const ReviewTypeSelector = ({ options, onSelect }) => {
  const [selectedOption, setSelectedOption] = useState(options[0]); // Initialize with the first option

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedOption(selectedValue);
    onSelect(selectedValue); // Call the callback function with the selected value
  };

  return (
    <div>
      <label class="form-label fw-bold me-3">Select Case or Video:</label>
      <select value={selectedOption} onChange={handleSelectChange}>
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
}
