import { useContext, useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { SessionContext, dvSrv } from "./App";
import { GeneratePassword } from "./model/User";
import { toast } from "react-toastify"
import { Tooltip } from "react-tooltip"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCopy, faEyeSlash, faEye} from "@fortawesome/free-solid-svg-icons"
import ReactJson from "@microlink/react-json-view";

export const SignIn = () => {
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [rememberMe, setRememberMe] = useState(false)
  const [errBar, setErrBar] = useState("")
  
  const navigate = useNavigate()
  const session = useContext(SessionContext)
  const location = useLocation();
  const navLink = location.state?.from?.pathname || "/dashboard/"

  const handleSignIn = async () => {
    // Temporary blocking of web access for one mobile upload user
    if(email === "medstarupload"){
      session.setData(null)
      toast.error("The user, "+email+", is not authorized with web access. Try with different user!")
     return
    }
    axios.post(dvSrv + "/api/signin", {
      username: email,
      password: password,
      LongExpiration: rememberMe,
    }, {withCredentials: true})
    .then(({data}) => {
      // Temporary blocking of web access for one mobile upload user
      if(data.username === "medstarupload"){
        session.setData(null)
        toast.error("The user, "+data.username+", is not authorized with web access. Try with different user!")
        // navigate('/signin')
      } else if (data.MFAEnabled) {
        // go to MFA verification
        navigate("/signin/mfa")
      } else if (data.NumSignedIn === 1) {// first time logging in, reset
        session.setData(data)
        navigate("/profile")
        //alert("This is the first time you signed in, please RESET your initial password!");
        //toast.warning("This is the first time you signed in, please RESET your initial password!!")
      } else {
        session.setData(data)
        navigate(navLink, { replace: true })
      }
    })
    .catch((error) => {
      session.setData(null)
      if (error.response && error.response.status === 401) {
        setErrBar("Wrong username or invalid password. Please try again.")
      } else { setErrBar("Error: " + error) }
    })
  }

  return (
    <div class="form-signin w-100 m-auto">
      {/* <form> */}
        {/* <img class="mb-4" src={logo} alt="" width="72" height="72" /> */}
        <h1 class="h3 mb-3 fw-normal">Please sign in</h1>
    
        <div class="form-floating">
          <input data-cy="username" type="email" class="form-control" id="floatingInput" placeholder="name@example.com" value={email} onChange={e => setEmail(e.target.value)} />
          <label for="floatingInput">Email address</label>
        </div>
        <div class="form-floating">
          <input data-cy="password" type="password" class="form-control" id="floatingPassword" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} />
          <label for="floatingPassword">Password</label>
        </div>
    
        <div class="form-check text-start my-3">
          <input class="form-check-input" type="checkbox" id="flexCheckDefault" value={rememberMe} onChange={()=>setRememberMe(!rememberMe)} />
          <label class="form-check-label" for="flexCheckDefault">
            Remember me for 30 days
          </label>
        </div>
        <Link to={`/`}>
          <div class="my-3">
            <span>Read Disclaimer</span>
          </div>
        </Link>
        <button class="btn btn-primary w-100 py-2" onClick={handleSignIn}>Sign in</button>
        <Link to={`/signin/forgot`}>
          <div class="my-3">
            <span>Forgot password?</span>
          </div>
        </Link>
          <div class="my-3">
            <span>If your Admin registered your Microsoft email with DeepVin, </span>
            <Link to={`/sso`}>
            <span>Sign in with SSO</span>
            </Link>
        </div>

        <p>{errBar && errBar}</p>
        {/* <p class="mt-5 mb-3 text-body-secondary">&copy; DeepVin, Inc. 2023</p> */}
      {/* </form> */}
    {/* <ReactJson src={{data}} /> */}
    </div>
  )
}

export const ForgotCredential = () => {
  const [email, setEmail] = useState("")
  const [passcode, setPasscode] = useState("")
  const [verifying, setVerifying] = useState(false)
  const [verified, setVerified] = useState(false)
  const [errBar, setErrBar] = useState("")

  const navigate = useNavigate()

  const handleRequestPasscode = async () => {
    axios.post(dvSrv + "/api/passcode", {
      Username: email,
      Passcode: passcode,
    }, {withCredentials: true})
    .then(({data}) => {
      toast.success("Passcode was sent to the email address!")
      setVerifying(true)
      setErrBar("")
    })
    .catch((error) => {
      setVerifying(false)
      setErrBar("Error: " + error)
    })
  }

  const handleVerifyPasscode = async () => {
    axios.post(dvSrv + "/api/passcode", {
      Username: email,
      Passcode: passcode,
    }, {withCredentials: true})
    .then(({data}) => {
      toast.success("Passcode was verified! Please continue to set new password.")
      setVerified(true)
      setErrBar("")
    })
    .catch((error) => {
      setVerifying(false) // resend the passcode
      setVerified(false)
      setErrBar("Error: " + error)
    })
  }

  const handleNewPassword = (success) => {
    if(success){  
      navigate("/signin")
    } else{
      navigate("/signin/forgot")
    }
  }

  return (
    <>
    <div class="form-signin w-100 m-auto">
      {/* <form> */}
        {/* <img class="mb-4" src={logo} alt="" width="72" height="72" /> */}
        <h3 class="h3 mb-3 fw-normal">Your username (email)</h3>
    
        <div class="form-floating">
          <input type="email" class="form-control mb-3" id="floatingInput" placeholder="name@example.com" value={email} onChange={e => setEmail(e.target.value)} />
          <label for="floatingInput">Email address</label>
        </div>
        { verifying && <div class="form-floating">
          <input type="text" class="form-control mb-3" id="floatingPasscode" placeholder="Passcode" value={passcode} onChange={e => setPasscode(e.target.value)} />
          <label for="floatingPassword">Passcode from email you received</label>
        </div> }
        
        { verified ? 
          <UserNewPassword email={email} passcode={passcode} handleUserNewPassword={handleNewPassword}/> :
          <button class="btn btn-primary w-100 py-2" 
            onClick={verifying ? handleVerifyPasscode : handleRequestPasscode}>{verifying ? "Submit" : "Continue"}</button>
        }
        <p>{errBar && errBar}</p>
        {/* <p class="mt-5 mb-3 text-body-secondary">&copy; DeepVin, Inc. 2023</p> */}
      {/* </form> */}
    </div>
    {/* <ReactJson src={{email, passcode}} /> */}
    </>
  )
}

export const UserNewPassword = ({email, passcode, handleUserNewPassword}) => {

  const [newPassword, setNewPassword] = useState('')
  const [newPassword2, setNewPassword2] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const newPasswordCallback = (success) => {
    handleUserNewPassword(success)
  }

  const onSetNewPassword = async () => {
    axios.post(dvSrv + "/api/resetpassword", {
      Username: email,
      Passcode: passcode,
      NewPassword: newPassword,
    }, {withCredentials: true})
    .then(({data}) => {
      toast.success("User's new password is set successfully. Please sign in with new password")
      newPasswordCallback(true)
    })
    .catch((error) => {
      console.error("Error: ", error)
      toast.error(error.response.data.error)
      newPasswordCallback(false)
    })
  }

  const validPassword = 
    newPassword === newPassword2 
    && newPassword.length > 5
 
  const copyPassword = () => {
    if (newPassword) {
      navigator.clipboard.writeText(newPassword);
      toast.success("Password copied to the clipboard.")
    }
  }
  const randomPassword = () => {
    let password = GeneratePassword(8);
    setNewPassword(password);
    setNewPassword2(password)
  }

  return (
    <>
    <h4 class="mt-5 mb-3">Set New Password</h4>
    <div class="row">
      <label for="inputPassword" class="col-4 col-form-label">Password</label>
      <div class="col-8">
        <div class="row input-group input-group-sm align-items-center">
          <button class="col-3 btn btn-outline-secondary btn-sm me-2" id="auto-gen-password" onClick={randomPassword}>Auto</button>
          <input type={showPassword ? "text" : "password"} class="col-8 form-control" id="inputPassword" value={newPassword} onChange={e => setNewPassword(e.target.value)}/>
          <button class="col-1 btn btn-sm" id="show-password" onClick={()=>setShowPassword(!showPassword)}>
            {showPassword 
            ? <FontAwesomeIcon icon={faEyeSlash} />
            : <FontAwesomeIcon icon={faEye} />
          }
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <label for="inputPassword2" class="col-6 col-form-label">Retype Password</label>
      <div class="col-6">
        <div class="row input-group input-group-sm align-items-center">
          <input type={showPassword ? "text" : "password"} class="col-8 form-control" id="inputPassword2" value={newPassword2} onChange={e => setNewPassword2(e.target.value)}/>
          <button class="col-1 btn btn-sm" onClick={copyPassword} id="copy-password"><FontAwesomeIcon icon={faCopy} /></button>
        </div>
      </div>
    </div>
    <div class="row mb-3"><span>{newPassword !== newPassword2 && "Password do not match!"}</span></div>
    <button class="btn btn-primary w-100 py-2" onClick={onSetNewPassword} disabled={!passcode || !validPassword}>Submit</button>

    <Tooltip anchorSelect="#show-password" place="top">Show password</Tooltip>
    <Tooltip anchorSelect="#copy-password" place="bottom">Copy password to clipboard</Tooltip>
    <Tooltip anchorSelect="#auto-gen-password" place="top">Auto generate password</Tooltip>

    {/* <ReactJson src={{email, passcode, newPassword, newPassword2}} /> */}
  </>
  )
}
