import axios from "axios";
import { useState, useEffect, useContext } from "react";
import { GetNodeTeam, GetNodeUsers, GetNodeParentUsers, GetNodeParentTeam } from "../model/Team";
import { dvSrv } from "../App";
import { SessionContext } from "../App";

export default function DataProvider(url) {
  const session = useContext(SessionContext)
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [isFetching, setIsFetching] = useState(false);  

  useEffect( () => {
    const axiosGet = async () => {
      setIsFetching(true);
      setError(null);

      axios.get(url, {withCredentials: true})
      .then(({data}) => {
        setData(data)
        setIsFetching(false)
      })
      .catch(error => {
        if (error.response.status === 401) {
          session.setData(null)
        } else { 
          console.error("Error: ", error) 
          setIsFetching(false)
          setError(error)
        }
      })
    }

    axiosGet();
    }, [url])

  return data // do NOT use {data}
}

export const useTeamAndUser = () => {
  const session = useContext(SessionContext)

  const [teamTree, setTeamTree] = useState({})
  const teams = GetNodeTeam(teamTree)
  const users = GetNodeUsers(teamTree)

  useEffect(() => {
    let ignore = false
    axios.get(dvSrv + `/api/team/tree`, {withCredentials: true})
    .then(({data}) => {
      if (!ignore) setTeamTree(data)
    })
    .catch(error => {
      if (error.response.status === 401) {
        session.setData(null)
      } else { console.error("Error: ", error) }
    })
    return () => {
      ignore = true
    }
  }, [])
  
  return [teams, users]
}

// TODO active
export const useActiveTemplate = (name) => {
  const session = useContext(SessionContext)
  const [template, setTemplate] = useState([])

  useEffect(() => {
    let ignore = false
    axios.get(dvSrv + `/api/template/`, {withCredentials: true})
    .then(({data}) => {
      if (!ignore) setTemplate(data.find(t => t.Name === name))
    })
    .catch(error => {
      if (error.response.status === 401) {
        session.setData(null)
      } else { console.error("Error: ", error) }
    })
    return () => {
      ignore = true
    }
  }, [])
  
  return template
}

// Returning teams (including parent->grandparent team(s)) and uses in these teams
export const useTeamAndAllUsers = () => {
  const session = useContext(SessionContext)

//  const [parentTree, setParentTree] = useState({})
  const [users, setUsers] = useState([])
  const [teams, setTeams] = useState([])
    
  useEffect(() => {
    let ignore = false
    axios.get(dvSrv + `/api/team/parenttree`, {withCredentials: true})
    .then(({data}) => {
      if (!ignore) {
//        setParentTree(data)
        const tusers = GetNodeUsers(data)
        const pusers = GetNodeParentUsers(data.Parent)
        setUsers(pusers ? [...tusers, ...pusers] : tusers)

        const cteams = GetNodeTeam(data)
        const pteams = GetNodeParentTeam(data.Parent)
        setTeams(pteams ? [...cteams, ...pteams] : cteams)

      }
    })
    .catch(error => {
      if (error.response && error.response.status === 401) {
        session.setData(null)
      } else { console.error("Error: ", error) }
    })
    return () => {
      ignore = true
    }
  }, [])

  return [teams, users] 
}

export const useDevices = () => {
  const session = useContext(SessionContext)
  const [devices, setDevices] = useState([])

  useEffect(() => {
    let ignore = false
    axios.get(dvSrv + `/api/device/self`, {withCredentials: true})
    .then(({data}) => {
      if (!ignore) {
        setDevices(data)
      }
    })
    .catch(error => {
      if (error.response && error.response.status === 401) {
        session.setData(null)
      } else { console.error("Error: ", error) }
    })
    return () => {
      ignore = true
    }
  }, [])

  return devices
}
