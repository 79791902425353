import React, { useEffect, useState } from 'react';
import { MarkdownWithAnchors } from './MarkdownViewer';

export const UserTutorial = () => {
  const [tutorialMarkdown, setTutorialMarkdown] = useState('');

  useEffect(() => {
    const handleInternalNavigation = (event) => {
      event.preventDefault();
      const targetId = event.target.getAttribute('href').substring(1); // Remove the # symbol
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    };
  
    // Attach a click event listener to all anchor links within the tutorial content.
    const anchorLinks = document.querySelectorAll('a[href^="#"]');
    anchorLinks.forEach((link) => {
      link.addEventListener('click', handleInternalNavigation);
    });
  
    return () => {
      // Clean up event listeners when the component unmounts.
      anchorLinks.forEach((link) => {
        link.removeEventListener('click', handleInternalNavigation);
      });
    };
  }, []);
  
 
  useEffect(() => {
    // Fetch the Markdown content (e.g., tutorial1.md)
    fetch('../tutorial/Tutorial.md')
      .then((response) => response.text())
      .then((data) => {
        setTutorialMarkdown(data);
      })
      .catch((error) => {
        console.error('Error fetching Tutorial Markdown:', error);
      });
  }, []);

  return (
    <div>
      <MarkdownWithAnchors markdownContent={tutorialMarkdown} />
    </div>
  );
}

