import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward, faCamera, faChevronLeft, faChevronRight, faDatabase, faDownload, faEdit, faEye, faEyeSlash, faFileEdit, faFileVideo, faForward, faHourglassEnd, faHourglassStart, faMaskFace, faPause, faPhotoFilm, faPhotoVideo, faPlay, faUndoAlt, faUserLock, faUserSecret, faUserShield, faUserSlash, faVideoCamera, faVideoSlash } from '@fortawesome/free-solid-svg-icons';
import { saveAs } from 'file-saver'
import { Tooltip } from 'react-tooltip';
import { SessionContext } from "../App"
import { useContext } from "react"
import axios from 'axios';

import { dvSrv } from "../App"
import { toast } from 'react-toastify';

export const VideoPlayer = ({invideo, playerRef, inPlaying, isFocused, onFocus, playersCallback}) => {
  const {data} = useContext(SessionContext)
  const [playing, setPlaying] = useState(inPlaying)
  const [playRate, setPlayRate] = useState(1)
  const [url, setUrl] = useState("")
  const [showEditor, setShowEditor] = useState(false)
  const [startTime, setStartTime] = useState(0)
  const [endTime, setEndTime] = useState(0)
  const [duration, setDuration] = useState(0)
  //const [refreshUrl, setRefreshUrl] = useState()
  const [video, setVideo] = useState(invideo)
  const [hideTS, setHideTS] = useState(false)

  useEffect(() => {
    axios.get(dvSrv + '/api/video/' + video.ID +'/url', {withCredentials: true})
      .then(({data}) => {
        let fullurl = isS3PresignedURL(data) ? data: (dvSrv + data)
        setUrl(fullurl)
        console.log('Setting url to: ', data);
      })
      .catch(error => console.error("Error fetching video url: ", error));
  }, [video]);

  useEffect(() => {
    setPlaying(inPlaying)
    console.log('Try to set playing inside player:', playing);
  }, [inPlaying]);

  const setPlayingStatus = (play) => {
    // isFocused = true
    onFocus(video.ID)

    setPlaying(play)
    playersCallback(play)
  }

  const handleLeft = () => {
    setPlayingStatus(false)
    playerRef.current.seekTo(playerRef.current.getCurrentTime() - 0.25) //0.333)
  }  
  const handleRight = () => {
    setPlayingStatus(false)
    playerRef.current.seekTo(playerRef.current.getCurrentTime() + 0.25) //0.333)
  }

  const onFlipEditor = (show) => {
    setShowEditor(show)
    if (show && playerRef && playerRef.current) {
      let val = Math.floor(playerRef.current.getCurrentTime() - 5)
      val = val<0 ? 0 : val
      setStartTime(val)
      //let duration = playerRef.current.getDuration()
      let endval =  duration > 0 && (val+10) > duration ? duration : (val+10)
      setEndTime(endval)
      //console.log(`startTime: ${startTime}, set new end time: ${endval}, endTime: ${endTime}\n`)
    }

  }
  const onSetStart = (e) => {
    e.preventDefault();
    if (playerRef && playerRef.current) {
      let st = Math.floor(playerRef.current.getCurrentTime())
      setStartTime(st)
      if (endTime < st) setEndTime(st)
    } 
  }
  const onSetEnd = (e) => {
    e.preventDefault();
    if (playerRef && playerRef.current) {
      let st = Math.ceil(playerRef.current.getCurrentTime())
      if (startTime < st) {
        setEndTime(st)
      } else {
        setEndTime(startTime)
      }
    } 
  }
  const handleStartChange = (e) => {
    let newValue = parseInt(e.target.value, 10);
    if (duration > 0 && newValue > duration) return
    setStartTime(newValue)
    if (endTime < newValue) setEndTime(newValue)
    if (playerRef && playerRef.current) {
      setPlayingStatus(false)
      playerRef.current.seekTo(newValue)
    }
  }
  const handleEndChange = (e) => {
    let newValue = parseInt(e.target.value, 10);
    if (newValue < startTime || (duration > 0 && newValue > duration)) return
    setEndTime(newValue)
    if (playerRef && playerRef.current) {
      setPlayingStatus(false)
      playerRef.current.seekTo(newValue)
    }
  }
  const handleSaveImage = () =>{
    if(playerRef && playerRef.current){
      saveFrameAsImage(playerRef.current.getCurrentTime(),
                      'Case_' + video.CaseNumber + '.png')
    }
  }
  async function saveFrameAsImage(timestamp, filename) {
    try {
      document.body.style.cursor = 'wait'
      const response = await axios.post(dvSrv + `/api/video/${video.ID}/frame`, 
      {TimeStamp: timestamp.toString(), HideTimeStamp: hideTS},
      { responseType: 'blob' , withCredentials: true})
      const pngBlob = new Blob([response.data], { type: 'image/png' })
      
      saveAs(pngBlob, filename)
      document.body.style.cursor = 'default'

    } catch (error) {
      document.body.style.cursor = 'default'
      console.error("Error extracting video frame from server:", error)
    }
  }
  const handleDownloadVideo = () =>{
    if(playerRef && playerRef.current){
      let vidLen = endTime===startTime? 1 : endTime - startTime + 1
      DownloadVideo(video.ID, startTime, vidLen, 'Case_' + video.CaseNumber + '.mp4', hideTS)
    }
  }
  const handleSaveVideo = () =>{
    if (!window.confirm("This will save the cropped video as latest video for this case, \n and subsequent review, report and notes will be based on this new video.")) {
      return
    }
    if(playerRef && playerRef.current){
      document.body.style.cursor = 'wait'
      
      let vidLen = endTime===startTime? 1 : endTime - startTime + 1
      if(hideTS && vidLen > 60){
        toast.info("The timestamp processing of 60+ seconds video will normally take more than 10 seconds")
      }
      axios.post(dvSrv + `/api/video/${video.ID}/extract`,
        {Start: startTime.toString(), Duration: vidLen.toString(), Save: "true", HideTimeStamp: hideTS}, 
        {withCredentials: true})
      .then(({data}) => {
        setVideo(data)
        //setRefreshUrl(!refreshUrl)
        setStartTime(0)
        setEndTime(0)
        document.body.style.cursor = 'default'
      })
      .catch (error => {
        document.body.style.cursor = 'default'
        console.error("Error saving video on server:", error)
      })
    }
  }
  const handleReloadOriginal = () =>{
    if(playerRef && playerRef.current){
      const formNote = new FormData()
      formNote.append('Version', 0)

      axios.put(dvSrv + `/api/video/${video.ID}`,
        formNote, {withCredentials: true})
      .then(({data}) => {
        setVideo(data)
        //setRefreshUrl(!refreshUrl)
        setStartTime(0)
        setEndTime(0)
      })
      .catch (error => {
        console.error("Error saving video on server:", error)
      })
    }
  }
  
  return (
  <>
    <div className={`row ${isFocused ? "active-player" : ""}`} onClick={() => {onFocus(video.ID)}}>
      <div class="col-12">
        <div class="pt-3">
          <ReactPlayer 
              ref={playerRef}
              url={url}
              controls muted
              // Disable download button
              config={{ file: { attributes: { controlsList: 'nodownload' } } }}
              // Disable right click
              onContextMenu={e => e.preventDefault()}
              width="100%" //{1088}
              height="100%" //{720}
              playing={playing}
              onPlay={()=>setPlayingStatus(true)}
              onPause={()=>setPlayingStatus(false)}
              //onEnded={handleEnd}
              playbackRate={playRate}
              // onProgress={progressHandler}
              onDuration={setDuration}
          />
        </div>
        <div>
          <div class="btn-group btn-group-sm pe-3">
              <button class="btn btn-outline-secondary" onClick={()=>setPlaying(!playing)}>{playing ? <FontAwesomeIcon icon={faPause} /> : <FontAwesomeIcon icon={faPlay} />}</button>
              <button class="btn btn-outline-secondary" onClick={()=>playerRef.current.seekTo(playerRef.current.getCurrentTime() - 5)}><FontAwesomeIcon icon={faBackward} /></button>
              <button class="btn btn-outline-secondary" onClick={()=>playerRef.current.seekTo(playerRef.current.getCurrentTime() + 5)}><FontAwesomeIcon icon={faForward} /></button>
          </div>
          <div class="btn-group btn-group-sm px-3">
              <button class="btn btn-outline-secondary" onClick={()=>setPlayRate(1)}>x1</button>
              <button class="btn btn-outline-secondary" onClick={()=>setPlayRate(2)}>x2</button>
              <button class="btn btn-outline-secondary" onClick={()=>setPlayRate(4)}>x4</button>
          </div>
          <div class="btn-group btn-group-sm px-3">
              <button class="btn btn-outline-secondary" onClick={handleLeft}><FontAwesomeIcon icon={faChevronLeft} /></button>
              <button class="btn btn-outline-secondary" onClick={handleRight}><FontAwesomeIcon icon={faChevronRight} /></button>
          </div>
          {data && (data.VideoEdit || data.IsRoot) && <div class="btn-group btn-group-sm px-3">
              <button id="btn-edit-video" class="btn btn-outline-secondary" onClick={()=>onFlipEditor(!showEditor)}><FontAwesomeIcon icon={faPhotoVideo} /></button>
          </div>}
          { showEditor &&
          <div class="row">
            <div class="col-3">
              <div class="input-group input-group-sm align-items-center">
                <button class="btn btn-outline-secondary btn-sm me-1" 
                  onClick={onSetStart} type="button" id="btn-start-time">
                  <FontAwesomeIcon icon={faHourglassStart} />
                </button>
                <input type="number" class="form-control me-1"
                  min={0} // max={video.Duration} 
                  value={startTime} onChange={handleStartChange}
                  placeholder="Start time" aria-label="Start time" 
                  aria-describedby="btn-start-time" 
                />
              </div>
            </div>
            <div class="col-3">
              <div class="input-group input-group-sm align-items-center">
                <button class="btn btn-outline-secondary btn-sm me-1" 
                  onClick={onSetEnd}
                  type="button" id="btn-end-time">
                    <FontAwesomeIcon icon={faHourglassEnd} />
                </button>
                <input type="number" class="form-control me-1"
                  min={0}  // max={video.Duration} 
                  //disabled={!useEndCheck}
                  value={endTime} onChange={handleEndChange}
                  placeholder="End time" aria-label="End time" 
                  aria-describedby="btn-end-time" 
                />
              </div>
            </div>
            <div class="col-4">
              <button id="btn-blur-timestamp" class="btn btn-outline-secondary btn-sm" style={{borderColor: '#17a2b8'}} onClick={()=>setHideTS(!hideTS)}><FontAwesomeIcon icon={hideTS ? faEyeSlash : faEye} color="#17a2b8"/></button>
              <button id="btn-crop-video" class="btn btn-outline-secondary btn-sm" onClick={()=>handleDownloadVideo()}><FontAwesomeIcon icon={faVideoCamera} /></button>
              <button id="btn-download-frame" class="btn btn-outline-secondary btn-sm me-3" onClick={()=>handleSaveImage()}><FontAwesomeIcon icon={faCamera} /></button>
              <button id="btn-replace-video" class="btn btn-outline-secondary btn-sm" style={{borderColor: '#ffc107'}} onClick={()=>handleSaveVideo()}><FontAwesomeIcon icon={faDatabase} color="#ffc107"/></button>
              <button id="btn-reset-original" class="btn btn-outline-secondary btn-sm" disabled={video.Version===0} onClick={()=>handleReloadOriginal()}><FontAwesomeIcon icon={faUndoAlt} /></button>
            </div>
          </div>
          }
        </div>
        <div class="py-3 fw-bold">{video.FileName}</div>
      </div>
    </div>

    {/* {playerRef && playerRef.current && <FrameCaptureModal showDlg={showFrameModal} setShow={setShowFrameModal} 
    video={video} videoContent={playerRef.current.getInternalPlayer()} timeStamp={playerRef.current.getCurrentTime()} />} */}
      <Tooltip anchorSelect="#btn-download-frame" place="top">Download current video frame</Tooltip>
      <Tooltip anchorSelect="#btn-crop-video" place="top">Download video from start to end time</Tooltip>
      <Tooltip anchorSelect="#btn-replace-video" place="top">Save video from start to end time and replace original in database!</Tooltip>
      <Tooltip anchorSelect="#btn-reset-original" place="top">Reload original video</Tooltip>
      <Tooltip anchorSelect="#btn-edit-video" place="top">Toggle video editing tools</Tooltip>
      <Tooltip anchorSelect="#btn-start-time" place="top">Set start time to current frame</Tooltip>
      <Tooltip anchorSelect="#btn-end-time" place="top">Set end time to current frame</Tooltip>
      <Tooltip anchorSelect="#btn-blur-timestamp" place="top">
        <span>Hide timestamp in extracted video.</span><br />
        <span>If the extracted video is more than 60 seconds,</span><br />
        <span>the process could take tens of seconds!</span>
      </Tooltip>
    </>
  )
}

export const Players = ({videos, playerRefs, setActiveVid, playingStatus, setCasePlayingStatus}) => {
  const [focusedVid, setFocusedVid] = useState()
  const [activePlaying, setActivePlaying] = useState(playingStatus)
  const [vidPlaying, setVidPlaying] = useState({});

  const handlePlayersPlayingStatus = (play) => {
    if (focusedVid){
      setVidPlaying({...vidPlaying, [focusedVid]: play})
    }
    setActivePlaying(play)
    setCasePlayingStatus(play)
  }

  const onFocus = (vid) => {
      setFocusedVid(vid)
      setActiveVid(vid)
  }

  useEffect(() => {
    videos.forEach(v => vidPlaying[v.ID] = false)

    if (videos.length > 0 && !focusedVid)
        onFocus(videos[0].ID)

    setActivePlaying(playingStatus)
  }, [videos, activePlaying])

  return (
    <>
    <div class="row">
      <div class="col-12">
        {videos.map(v => (
          v.MediaType === 2 ?
          <div class="py-3"><ImageViewer image={v}/> </div> : 
          (v.MediaType === 1 || v.MediaType === 0) ?
          <div class="py-3">
            <VideoPlayer invideo={v} isFocused={focusedVid === v.ID} onFocus={onFocus} playerRef={playerRefs[v.ID]} inPlaying={focusedVid === v.ID ? activePlaying : vidPlaying[v.ID]} playersCallback={handlePlayersPlayingStatus} />
          </div> : null          
        ))}
      </div>
    </div>
    </>
  )
}

export  async function DownloadVideo(id, start, duration, filename, hideTS) {
    try {
      if(hideTS && duration > 60){
        toast.info("The timestamp processing of 60+ seconds video will normally take more than 10 seconds")
      }
      document.body.style.cursor = 'wait'
      const response = await axios.post(dvSrv + `/api/video/${id}/extract`,
                        {Start: start.toString(), Duration: duration.toString(), Save: "false", HideTimeStamp: hideTS}, {responseType: 'blob' , withCredentials: true})
      const vidBlob = new Blob([response.data], { type: 'video/mp4' })
      
      saveAs(vidBlob, filename)
      document.body.style.cursor = 'default'

    } catch (error) {
        document.body.style.cursor = 'default'
        console.error("Error extracting short video from server:", error)
    }
}

const isS3PresignedURL = (inUrl) => {
  // Check if it's a relative path
  if (inUrl.startsWith('/')) {
      return false
  } else if (inUrl.includes(".s3.") && inUrl.includes(".amazonaws.com")) {
    return true
  }

  return false

}

export const ImageViewer = ({image}) => {
  const [url, setUrl] = useState("")

  useEffect(() => {
    axios.get(dvSrv + '/api/video/' + image.ID +'/url', {withCredentials: true})
      .then(({data}) => {
        let fullurl = isS3PresignedURL(data) ? data: (dvSrv + data)
        setUrl(fullurl)
        console.log('Setting image url to: ', data);
      })
      .catch(error => console.error("Error fetching image url: ", error));
  }, [image]);
  
  return (
    <div class="col-12">
      <div>
        <img src={url} width="100%" height="100%" alt="Image File" />
      </div>
      <div class="py-3 fw-bold">{image.FileName}</div>
    </div>
  )
}