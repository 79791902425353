import { useState, useEffect, useContext } from 'react';
import ReactJson from '@microlink/react-json-view';
import axios from 'axios';
import { dvSrv } from "../App"
import { SessionContext } from '../App';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-timezone';
import DataProvider from '../hook/DataProvider';
import { AccountPolicy, AccountSettings, TimeStringForTimeZone } from "./Setting"
import { toast } from "react-toastify"

export const Templates = () => {
  const data = DataProvider(dvSrv + `/api/template/`)

  if (data)
  return (
    <>
    <div class="row">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button class="nav-link active internal-tab" id="report-tab" data-bs-toggle="tab" data-bs-target="#report" type="button" role="tab" aria-controls="report" aria-selected="false">Report Template</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link internal-tab" id="review-tab" data-bs-toggle="tab" data-bs-target="#review" type="button" role="tab" aria-controls="review" aria-selected="false">QA Review Template</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link internal-tab" id="policy-tab" data-bs-toggle="tab" data-bs-target="#policy" type="button" role="tab" aria-controls="policy" aria-selected="false">Account Policy</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link internal-tab" id="acct-tab" data-bs-toggle="tab" data-bs-target="#acct" type="button" role="tab" aria-controls="acct" aria-selected="false">Other Settings</button>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="report" role="tabpanel" aria-labelledby="report-tab">
          <Template type={1} />
        </div>
        <div class="tab-pane fade" id="review" role="tabpanel" aria-labelledby="review-tab">
          <Template type={2} />
        </div>
        <div class="tab-pane fade" id="policy" role="tabpanel" aria-labelledby="policy-tab">
          <AccountPolicy />
          {/* <h3>Account logo</h3>
          <h3>Centralized device list and passwords control</h3>
          <h3>Limit users to access their own files only. Yes/No</h3> */}
        </div>
        <div class="tab-pane fade" id="acct" role="tabpanel" aria-labelledby="acct-tab">
          <AccountSettings />
        </div>
      </div>
    </div>
    {/* <ReactJson src={data} collapsed={1} /> */}
    </>
  )
}

export const Template = ({type}) => {
  const session = useContext(SessionContext)
  const [data, setData] = useState()
  const [edit, setEdit] = useState(false)
  const [flag, setFlag] = useState(false)
  
  useEffect(() => {
    axios.get(dvSrv + `/api/template/${type}`, {withCredentials: true})
      .then(({data}) => {
        // TODO sort and apply seq number
        setData(data)
        setEdit(false)
      })
      .catch(error => {
        if (error.response.status === 401) {
          session.setData(null)
        } else { console.error("Error: ", error) }
      })
    }, [flag])

  const handleUpDown = (num, move) => {
    setEdit(true)

    let tfs = [...data.TemplateFields]
    let currentPos = num - 1 // Note: pos is 0-based, num is 1-based.
    let nextPos = currentPos + move

    if (nextPos > 0 && nextPos <= tfs.length) {
      let tmp = tfs[nextPos]
      if (tmp) {
        tfs[currentPos].Number = nextPos+1
        tfs[nextPos] = tfs[currentPos]

        tmp.Number = currentPos+1
        tfs[currentPos] = tmp
      }
    }
    setData({...data, TemplateFields: tfs})
  }

  const toggleEnabled = (num) => {
    let ts = [...data.TemplateFields]
    let index = num - 1
    ts[index].Enabled = !ts[index].Enabled
    setEdit(true)
    setData({...data, TemplateFields: ts})
  }
  const handleValuesChange = (e) => {
    const {name, value} = e.target
    let str = name.split('-')
    let index = parseInt(str[1]) - 1
    let ts = [...data.TemplateFields]
    ts[index][str[0]] = value
    setEdit(true)
    setData({...data, TemplateFields: ts})
  }

  const handleDuplicate = (num) => {
    let tf = [...data.TemplateFields]
    let index = num - 1
    let newtf = {...tf[index], ID: undefined, Number: tf.length+1}
    newtf.FieldName += "_New_"+(tf.length+1)
    newtf.Description = "Enter new description"
    newtf.FieldValues = "Enter 2-3 variables, separated variables by comma"
    setEdit(true)
    setData({...data, TemplateFields: [...tf, newtf]})
  }

  const variableType = ['bool', 'bool+', 'string', 'int']
  const onVariableTypeSelect = (num, selectedValue) => {
    let ts = [...data.TemplateFields]
    let index = num - 1
    ts[index].FieldType = selectedValue    
    setEdit(true)
    setData({...data, TemplateFields: ts})
  }

  const handleCancel = () => {
    setFlag(!flag) // revert back to the begining
  }

  // To save the whole table
  const handleApply = () => {
    axios.post(dvSrv + `/api/template/${type}`, data, {withCredentials: true})
      .then(({data}) => {
        setData(data)
        toast.success("Template is saved!")
      })
      .catch(error => {
        if (error.response.status === 401) {
          session.setData(null)
        } else { console.error("Error: ", error) }
      })
    setEdit(false)
  }

  if (data)
  return (
  <>
    <div class="row">
      <div class="col-8">
        <h3 class="mt-5 mb-3">{data.Type}</h3>
        <p class="text-muted mb-5">Last modified: {TimeStringForTimeZone(session, data.UpdatedAt)} ({moment(data.UpdatedAt).fromNow()})</p>
      </div>
      <div class="col-4">
        <button class={`btn btn-lg btn-${edit ? "primary" : "outline-secondary"} mt-5`} disabled={!edit} onClick={handleApply}>{edit ? "Apply" : "Applied"}</button>
        {edit && <button class="btn btn-lg btn-warning mt-5 ms-3" disabled={!edit} onClick={handleCancel}>Cancel</button>}
      </div>
    </div>
    <div>
      <table class="table table-hover">
        <thead>
          <th class="text-center text-muted">#</th>
          <th>Name</th>
          <th>Description</th>
          <th>Type</th>
          <th>Variables</th>
          <th class="text-center">Enabled</th>
          <th></th>
        </thead>
        <tbody>
          {data.TemplateFields && data.TemplateFields.sort((a,b) => a.Number - b.Number).map(field => <TemplateFieldTab 
            field={field}
            editing={edit}
            toggleEnabled={toggleEnabled}
            handleValuesChange={handleValuesChange}
            handleUpDown={handleUpDown}
            handleDuplicate={handleDuplicate}
            variableType={variableType}
            onVariableTypeSelect={onVariableTypeSelect} 
            />)}
        </tbody>
      </table>
    </div>      
    {/* <ReactJson src={{data}} collapsed={3} /> */}
  </>  
  )
}

export const TemplateFieldTab = ({field, editing, toggleEnabled, handleValuesChange, handleUpDown, handleDuplicate, variableType, onVariableTypeSelect}) => {

  // set edit mode for an unsaved new field after duplication
  const [edit, setEdit] = useState(field.ID===undefined)

  useEffect(() => {
    if (!editing) setEdit(false)
  }, [editing])

  const onSelect = (selectedValue) => onVariableTypeSelect(field.Number, selectedValue)

  return (
    <tr>
      <td class="text-center text-muted">{field.Number}</td>
      <td>
        {edit
        ? <input type="text" class="form-control-sm" name={"FieldName-"+field.Number} value={field.FieldName} placeholder="Name" onChange={handleValuesChange}/>
        : field.FieldName
        }
      </td>
      <td>
        {edit
        ? <input type="text" class="form-control-sm" name={"Description-"+field.Number} value={field.Description} placeholder="Description" onChange={handleValuesChange}/>
        : field.Description
        }
      </td>
      <td>
        {edit
        ? <TypeSelector value={field.FieldType} options={variableType} onSelect={onSelect} />
        : field.FieldType
        }
      </td>
      <td>
        {edit
        ? <input type="text" class="form-control-sm" name={"FieldValues-"+field.Number} value={field.FieldValues} placeholder="Variables" onChange={handleValuesChange}/>
        : field.FieldValues
        }
      </td>
      <td class="text-center">
        <input 
          class="form-check-input"
          type="checkbox"
          checked={field.Enabled}
          onChange={() => toggleEnabled(field.Number)}
        />
      </td>
      <td class="text-center">
        <div class="btn-group">
          <button class="btn btn-outline-secondary py-0" name="Up" onClick={()=>handleUpDown(field.Number, -1)}><FontAwesomeIcon icon={faArrowUp} /></button>
          <button class="btn btn-outline-secondary py-0" name='Down' onClick={()=>handleUpDown(field.Number, 1)}><FontAwesomeIcon icon={faArrowDown} /></button>
        </div>  &nbsp;
        <div class="btn-group" role="group">
          {edit
          ? <button class="btn btn-warning py-0" type="button" onClick={()=>setEdit(false)}>Exit</button>
          : <button class="btn btn-outline-secondary py-0" onClick={()=>setEdit(true)}>Edit</button>
          }
          <button class="btn btn-outline-secondary py-0" onClick={()=>handleDuplicate(field.Number)}disabled={edit}>Duplicate</button>
        </div>
      </td>
    </tr>
  )
}

export const TypeSelector = ({value, options, onSelect, label}) => {
  const [selectedOption, setSelectedOption] = useState(value || options[0])

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value
    setSelectedOption(selectedValue)
    onSelect(selectedValue) // Call the callback function with the selected value
  }

  return (
    <div>
      <label class="form-label fw-bold me-3">{label}</label>
      <select value={selectedOption} onChange={handleSelectChange}>
        {value && <option value={value} selected>{value}</option>}
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
}