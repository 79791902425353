import { faVideoCamera } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';

import { dvSrv } from "../App"
import { faClock } from "@fortawesome/free-regular-svg-icons";
import ReactJson from "@microlink/react-json-view";
import { SessionContext } from "../App";
import { DownloadVideo } from "./Player";
import { Tooltip } from 'react-tooltip';
import { TimeStringForTimeZone } from "./Setting"

export const Notes = ({video, playerRef, setCasePlayingStatus}) => {
  const session = useContext(SessionContext)
  
  const [notes, setNotes] = useState([])
  const [editID, setEditID] = useState()

  const [startTime, setStartTime] = useState(0)
  const [endTime, setEndTime] = useState(0)
  const [description, setDescription] = useState("")
  const [useEndCheck, setUseEndCheck] = useState(false)
  // the notes index should be currentNote - 1
  const [currentIndex, setCurrentIndex] = useState(0)
  
  const [flag, setFlag] = useState()
  const refresh = () => {
    setFlag(!flag)
  }
  
  useEffect(() => {
    axios.get(dvSrv + '/api/video/' + video.ID +'/notes', {withCredentials: true})
      .then(({data}) => setNotes(data))
      .catch(error => {
        if (error.response.status === 401) {
          session.setData(null)
        } else { console.error("Error: ", error) }
    })}, [video, playerRef, flag]);

  const jumpTo = (time) => {
    if (playerRef && playerRef.current) {
      playerRef.current.seekTo(time)
      setCasePlayingStatus(false)
    }
  }

  const onSetStart = (e) => {
    e.preventDefault();
   console.log("setting start time ... \n", playerRef);
    if (playerRef && playerRef.current) {
      let st = Math.floor(playerRef.current.getCurrentTime())

      setStartTime(st)
      if (useEndCheck && endTime < st) setEndTime(st)
    } 
  }
  const onSetEnd = (e) => {
    setEndTime(useEndCheck ? 0 : startTime) // DO this before setUseEndCheck !
    setUseEndCheck(!useEndCheck)
  }

// Handle the Start/End time input changes from Notes UI
  const handleStartChange = (e) => {
    let newValue = parseInt(e.target.value, 10);
    // let maxTime = useEndCheck && endTime > 0 ? endTime-1 : video.Duration
    // newValue = Math.min(newValue, maxTime);
    setStartTime(newValue)
    if (useEndCheck && endTime < newValue) setEndTime(newValue)
    if (playerRef && playerRef.current) {
      setCasePlayingStatus(false)
      playerRef.current.seekTo(newValue)
    }
  }

  const handleEndChange = (e) => {
    let newValue = parseInt(e.target.value, 10);
    if (newValue < startTime) return

    setEndTime(newValue)
    if (playerRef && playerRef.current) {
      // newValue = Math.max(newValue, startTime+1)
      // newValue = Math.min(newValue, video.Duration)
      setCasePlayingStatus(false)
      playerRef.current.seekTo(newValue)
    }

  }

  const setCurrent = (newCurrent) => {
    console.log("trying to set currentNode: ", newCurrent)
    if (playerRef && playerRef.current && newCurrent <= notes.length && newCurrent > 0) {
        let curNote = notes[newCurrent - 1]
        setCasePlayingStatus(false)
        playerRef.current.seekTo(curNote.StartTime);
        setStartTime(curNote.StartTime)
        setUseEndCheck(curNote.EndTime > 0)
        setEndTime(curNote.EndTime)

        setDescription(curNote.Description)
        // setFeedback(curNote.Feedback)
        setCurrentIndex(newCurrent)
    }
  }
  const onNewNote = (e) => {
    e.preventDefault()
    initUI();
  }

  const onSaveNote = () => {
    axios.post(dvSrv + `/api/note`, {
      RecordingFileID: video.ID,
      StartTime: startTime,
      EndTime: endTime,
      Description: description,
    }, {withCredentials: true})
    .then(({data}) => {
      setStartTime(0)
      setEndTime(0)
      setUseEndCheck(!useEndCheck)      
      setDescription("")
      refresh()    
    })
    .catch(error => {
      if (error.response.status === 401) {
        session.setData(null)
      } else {console.error("Error: ", error)}})
  }

  const onSaveNote2 = async(e) => {
    e.preventDefault();
    // use formData so that server can handle this update as a map without hard coding the keys
    const formNote = new FormData()
    formNote.append('StartTime', startTime)
    formNote.append('EndTime', useEndCheck ? endTime : 0)
    formNote.append('Description', description)
    // formNote.append('Feedback', feedback)
    if (currentIndex === 0) { // new note
      axios.post(dvSrv + '/api/video/' + video.ID +'/notes', formNote, {withCredentials: true})
        .then(response => {
          refresh()
          // setNotes([...notes, response.data])
          initUI()
        })
        .catch(error => console.error("Error adding note: ", error))
    } else if (currentIndex > 0) {
      let curNote = notes[currentIndex - 1]
      axios.put(dvSrv + `/api/note/${curNote.ID}`, formNote, {withCredentials: true})
        .then(response => {
          refresh()
          // setNotes(notes.map(note => note.ID === curNote.ID ? { ...note, ...response.data } : note));
        })
        .catch(error => console.error("Error saving note: ", error))
    }
  };

  const initUI = () =>{
    // Clear the inputs
    setStartTime(0)
    setEndTime(0)
    setDescription("")
    // setFeedback("")
    setCurrentIndex(0)
    setUseEndCheck(false)
  }

  if (notes)
  return (    
    <>
      <div>
        {notes.map(note => <NoteTab note={note} video={video} setID={setEditID} refresh={refresh} jumpTo={jumpTo} />)}
      </div>

      <div class="shadow p-3 mb-3 bg-body-tertiary rounded">
        <div class="fw-bold">
          New Note
        </div>
        <div class="row pt-3 pb-1">
          <div class="col-lg-4 col-sm-6">
            <div class="input-group input-group-sm align-items-center">
              <button class="btn btn-outline-secondary btn-sm" 
                onClick={onSetStart} type="button" id="button-startTime">
                <FontAwesomeIcon icon={faClock} /> Set Start
              </button>
              <input type="number" class="form-control me-1"
                min={0} // max={video.Duration} 
                value={startTime} onChange={handleStartChange}
                placeholder="Start time" aria-label="Start time" 
                aria-describedby="button-startTime" 
              />
            </div>
          </div>
          <div class="col-lg-4 col-sm-6">
            <div class="input-group input-group-sm align-items-center">
              <button class="btn btn-outline-secondary btn-sm" 
                onClick={onSetEnd}
                type="button" id="button-endTime">
                  <FontAwesomeIcon icon={faClock} /> Set End
              </button>
              <input type="number" class="form-control me-1"
                min={0}  // max={video.Duration} 
                disabled={!useEndCheck}
                value={endTime} onChange={handleEndChange}
                placeholder="End time" aria-label="End time" 
                aria-describedby="button-endTime" 
              />
            </div>
          </div>
          {/* <div class="col-4">
            <div class="input-group input-group-sm mb-3 align-items-center">
              <div class="form-check me-1 align-items-center">
                  <input 
                    class="form-check-input" 
                    type="checkbox" 
                    id="useEndCheck" 
                    checked={useEndCheck} 
                    onChange={onEndCheck} 
                    aria-label="Use End Check" 
                  />
                  <label class="form-check-label align-items-center" for="useEndCheck" >Set End</label>
              </div>

              <input type="number" class="form-control" width="100%"
                min={1} max={video.Duration} 
                placeholder="End time" aria-label="End Time Input"
                disabled={!useEndCheck} value={endTime} onChange={handleEndChange}>
              </input>
            </div>
          </div> */}
        </div>

        {/* <div class="form-floating"> */}
          <textarea class="form-control mb-3" placeholder="Comments" 
            style={{ height: '200px' }}
            value={description} onChange={e => setDescription(e.target.value)}
            id="timelapseComments" >
          </textarea>
          {/* <label for="timelapseComments">Notes</label>
        </div> */}
        <div>
          <button class="btn btn-sm btn-outline-primary py-0" onClick={onSaveNote} disabled={!session.data.NoteEdit}>Save</button>
        </div> 
      </div>
    {/* <ReactJson src={{startTime, endTime, editID, video, notes}} /> */}
  </>
  )
}

// TODO time will ultimately pin-pointed with video file's SyncedTime !!!
export const TimeStamp = ({start, end, jumpTo}) => {
  if (start)
  return (
    <button id="btn-set-frame" class="btn btn-sm btn-outline-secondary py-0" style={{width: '120px',}}
      onClick={()=>jumpTo(start)}
    >
      <FontAwesomeIcon icon={faClock} color="orange" /> &nbsp;
      {start}{end > 0 && <span>-{end}</span>}
    </button>
  )
}

export const NoteTab = ({note, video, setID, refresh, jumpTo}) => {
  const session = useContext(SessionContext)
  const [edit, setEdit] = useState(false)
  const [description, setDescription] = useState(note.Description)

  const handleEdit = (e) => {
    setEdit(!edit)
    if (edit) updateNote()
  }

  const handleSave = (e) => {
    if(note.EndTime > 0) {
      let vidLen = note.EndTime===note.StartTime ? 1 : note.EndTime - note.StartTime + 1
      DownloadVideo(note.RecordingFileID, note.StartTime, vidLen, 
        'Case_' + video.CaseNumber + '_Note_' + note.StartTime.toString() + "to" + note.EndTime.toString() + '.mp4', true)
    }
  }
  
  const handleDelete = (e) => {
    if (window.confirm("Please confirm you are going to delete this comment")) {
      axios.delete(dvSrv + `/api/note`, {
        ID: note.ID,
      }, {withCredentials: true})
      .then(({data}) => {
        refresh() 
      })
      .catch(error => {
        if (error.response.status === 401) {
          session.setData(null)
        } else {console.error("Error: ", error)}
      })
    }
  }

  const updateNote = () => {
    axios.put(dvSrv + `/api/note`, {
      ID: note.ID,
      // StartTime: note.StartTime,
      // EndTime: note.EndTime,
      Description: description,
    }, {withCredentials: true})
    .then(({data}) => {
      refresh()    
    })
    .catch(error => {
      if (error.response.status === 401) {
        session.setData(null)
      } else {console.error("Error: ", error)}
    })
  }

  return (
    <>
    <div class="shadow p-3 mb-3 bg-body-tertiary rounded">
      <div>
        <span class="fw-bold">{note.User.FirstName ? note.User.FirstName : note.User.username}</span> - <span class="pe-3">{TimeStringForTimeZone(session, note.CreatedAt)} - {TimeStringForTimeZone(session, note.UpdatedAt)}</span><TimeStamp start={note.StartTime} end={note.EndTime} jumpTo={jumpTo} />
        <button id="btn-download-video" class="btn btn-sm btn-outline-secondary py-0" disabled={note.EndTime ===0} onClick={handleSave}><FontAwesomeIcon icon={faVideoCamera}/></button>
      </div>
      <div class="py-3">
        {edit
        ? <textarea class="form-control" placeholder="Comment for current timelapse" 
            style={{ height: '100px' }}
            value={description} onChange={e => setDescription(e.target.value)}
            id="timelapseComments" >
          </textarea>
        : note.Description
        }
      </div>
      {/* <div class="d-grid gap-2 d-md-flex justify-content-md-end"> */}
      <div class="hstack gap-2">
        <button class="btn btn-sm btn-outline-secondary py-0" disabled={!session.data.NoteEdit || session.data.ID !== note.UserID} onClick={handleEdit}>{edit ? "Save" : "Edit"}</button>

        {/* Not working on the DELETE - CORS ??? */}
        {false && session.data.ID === note.UserID && 
        <button class="btn btn-sm btn-outline-secondary py-0" onClick={handleDelete} >Delete</button>}
        {/* <button class="btn btn-sm btn-outline-secondary py-0"
          onClick={()=>setID(note.ID)}>
            <FontAwesomeIcon icon={faPlus} /> Comment</button> */}
      </div>
      <Tooltip anchorSelect="#btn-download-video" place="top">Crop and download video from start to end time</Tooltip>
      <Tooltip anchorSelect="#btn-set-frame" place="top">Move video player frame to start time</Tooltip>
    </div>
    </>
  )
}