export const Home = () => { 
  return (
    <div className="disclaimer-container">
      <h1 className="disclaimer-title">Welcome to DEEPVIN<span class="fs-4" style={{verticalAlign: 'top' }}>&#8482;</span></h1>
      <h4 style={{ textAlign: 'center' }}>A Video Cloud for EMS</h4>
      <hr />
      <p>Please read this disclaimer carefully before using our services.</p>

      <h2 className="disclaimer-subtitle">Disclaimer of Responsibility</h2>
      <p>By using this website, you agree to the following terms and conditions regarding the use and sharing of data:</p>

      <ol className="disclaimer-list">
        <li>
          <strong>User Responsibility:</strong> The content and data hosted on this website, which are made available by you or your organization and may include sensitive patient information, are provided for your professional and lawful use. You are solely responsible for any actions you take with respect to the data, including sharing, distributing, or otherwise making the data available to unauthorized parties.
        </li>
        <li>
          <strong>No Liability for Unauthorized Sharing:</strong> <span className="website-name">DeepVin.com</span> explicitly disclaims any responsibility or liability for any data shared by you with unauthorized parties. This includes but is not limited to, sharing data with third parties, posting data on other platforms, or any other form of unauthorized distribution.
        </li>
        <li>
          <strong>User Conduct:</strong> You are expected to respect the confidentiality and integrity of the data you access through our website. Any misuse, including unauthorized sharing, is strictly prohibited and may result in suspension or termination of your access to our services.
        </li>
        <li>
          <strong>Indemnity:</strong> You agree to indemnify and hold harmless <span className="website-name">DeepVin.com</span>, its affiliates, employees, and partners from any claims, damages, or expenses arising out of or related to your unauthorized sharing of data.
        </li>
        <li>
          <strong>Changes to Disclaimer:</strong> <span className="website-name">DeepVin.com</span> reserves the right to modify this disclaimer at any time. It is your responsibility to review this disclaimer periodically for any changes.
        </li>
      </ol>

      <p>By accessing and using this website, you acknowledge that you have read, understood, and agreed to this disclaimer. If you do not agree with any part of this disclaimer, please do not use our website.</p>
      <p>For any questions or concerns, please contact us at <span className="contact-info">info@deepvin.com</span></p>
      <p>Thank you for using <span className="website-name">DeepVin.com</span></p>
    </div>
  )
}