import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { SessionContext } from "../App"
import { dvSrv } from "../App"
import ReactJson from "@microlink/react-json-view"
import { Link } from "react-router-dom"
import DataProvider from "../hook/DataProvider"
import { TeamDataTree } from "./Team"
import { TypeSelector } from "./Template"
import { Button, Modal } from "react-bootstrap"
import { TimeStringForTimeZone } from "./Setting"

const accountTypes = ['Demo', 'Test', 'Prospect', 'Resaler', 'Customer']

export const Accounts= () =>  {
  //const accounts = DataProvider(dvSrv + `/api/account/`)
  const session = useContext(SessionContext)
  const [accounts, setAccounts] = useState([]) 
  const [showAccountModal, setShowAccountModal] = useState(false)

  const [flag, setFlag] = useState(false)
  const refresh = () => {
    setFlag(!flag)
  }

  useEffect(() => {
    axios.get(dvSrv + "/api/account/", {withCredentials: true})
    .then(({data}) => {
      setAccounts(data)
    })
    .catch(error => {
      if (error.response.status === 401) {
        session.setData(null)
      } else { console.error("Error: ", error) }
    })
  }, [flag])

  if (accounts) {
  return (
  <>
    <div>
      <h1>Deepvin SuperAdmin Page !!!</h1>
      <h2> ALL Accounts</h2>
      <h5> Total {accounts.length} accounts</h5>
      <div>
        <AccountTable accounts={accounts} />
        {/* <ul>
        {accounts.map(account => 
          <li>
            <Link to={`/account/${account.ID}`}>{account.ID} - {account.Name}</Link>
          </li>
        )}
        </ul> */}
      </div>
      <button class="btn btn-outline-secondary me-3" onClick={()=>setShowAccountModal(true)}/*onClick={handleCreate}*/>Create Account</button>
    </div>
    {<AccountAddModal show={showAccountModal} setShow={setShowAccountModal} refresh={refresh}/>}
  </>
  )}
}

// export const Account = () => {  
//   return <TeamDataTree />
// }

export const AccountTable = ({accounts}) => {

  if (accounts)
  return (
    <table class="table table-hover">
    <thead>
      <th>ID</th>
      <th>Name</th>
      <th>Type</th> 
      <th>Users</th>
      <th>Devices</th> 
      <th>Files</th>
      <th>Total Size (MB)</th>
      <th>Annual Call Volume</th>
      <th>Retention (days)</th>
      <th>Monthly Fee</th>
      <th>Create Date</th>
      {/* <th>Last Updated</th> */}
      <th>Status</th>
    </thead>
    <tbody>
      {accounts.map(account => <AccountTab account={account}/>)}
    </tbody>
  </table>
  )
}

export const AccountTab = ({account}) => {
    const session = useContext(SessionContext)
    const [data, setData] = useState(account)
    const [disabled, setDisabled] = useState(account.Disabled)

    const handleChange = (field, val) => {     
      axios.post(dvSrv + `/api/account/${account.ID}/update`, {[field]: val}, {withCredentials: true})
      .then(({data}) => {
        setData(data)
        setDisabled(data.Disabled)
      })
      .catch(error => {         
        if (error.response.status === 401) {
        session.setData(null)
      } else {console.error("Error: ", error)} } )
    }

    const onTypeSelect = (selectedValue) => handleChange("AccountType", selectedValue)

     
    if (account)
    return (
      <>
      <tr>
        <td>{account.ID}</td>
        <td><Link to={`/team/${account.ID}`}>{account.Name}</Link></td>
        <td> {account.ID === 1 ? "--" 
          : <TypeSelector value={account.AccountType} options={accountTypes} onSelect={onTypeSelect} />}
        </td>
        <td>{account.ID === 1 ? "--" 
          : account.NumUsers}
        </td>
        <td>{account.ID === 1 ? "--" 
          : account.NumDevices}
        </td> 
        <td>{account.ID === 1 ? "--" 
          : account.NumFiles}
        </td>
        <td>{account.ID === 1 ? "--" 
          : Math.round(account.TotalFileSize/1024/1024)}
        </td>
        <td>{account.ID === 1 ? "--" 
          : account.AnnualCallVolume}
        </td>
        <td>{account.ID === 1 ? "--" 
          : account.FileRetentionPeriod}
        </td>
        <td>{account.ID === 1 ? "--" 
          : account.MonthlyFee}
        </td>
        <td>{TimeStringForTimeZone(session,account.CreatedAt)}</td>
        {/* <td>{TimeStringForTimeZone(session,account.UpdatedAt)}</td> */}
        <td> {account.ID === 1? "--" 
          : <button class="btn btn-sm btn-outline-secondary" onClick={() => handleChange("Disabled", !disabled)} /*disabled={account.Disabled}*/>{disabled ? "Enable" : "Disable"}</button>}
        </td>
      </tr>
      {/* <ReactJson src={{account}} /> */}
      </>
    )
}

const AccountAddModal = ({show, setShow, refresh}) => {

  const [accName, setAccName] = useState("")
  const [accSName, setAccSName] = useState("")
  const [accType, setAccType] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")

  const createAccount = (name, sname, type, email, fn, ln) => {
    axios.post(dvSrv + '/api/account/new', 
      {
        Name: name,
        ShortName: sname,
        AccountType: type,
        Username: email,
        FirstName: fn,
        LastName: ln
      }, 
      {withCredentials: true})
    .then(response => {
          refresh()
      }).catch(error => console.error("Error creating new account: ", error))
    }
  

  const onHide = () => setShow(false)
  const handleSubmit = () => {
    createAccount(accName, accSName,accType, email, firstName,lastName)
    setShow(false)
    refresh()
  }

  return (
    <Modal show={show} onHide={onHide} centered={true}>
      <Modal.Header closeButton>
        <Modal.Title>Create new account and its root user</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div class="input-group mb-3">
          <span class="input-group-text" id="basic-user">Account Name </span>
          <input type="text" class="form-control" placeholder="Account Name" aria-label="New account setup" aria-describedby="basic-addon2" value={accName} onChange={e=>setAccName(e.target.value)}/>
        </div>
        <div class="input-group mb-3">
          <span class="input-group-text" id="basic-user">Account Short Name </span>
          <input type="text" class="form-control" placeholder="Short Name" aria-label="New account setup" aria-describedby="basic-addon2" value={accSName} onChange={e=>setAccSName(e.target.value)}/>
        </div>
        <div class="input-group mb-3">
          <span class="input-group-text" id="basic-user">Account Type </span>
          <TypeSelector value={accType} options={accountTypes} onSelect={setAccType}/>
        </div>
        <hr />
        <h5>Root user</h5>
        <div class="input-group mb-3">
          <span class="input-group-text" id="basic-user">Username </span>
          <input type="text" class="form-control" placeholder="Email address" aria-label="New account setup" aria-describedby="basic-addon2" value={email} onChange={e=>setEmail(e.target.value)}/>
        </div>
        <div class="input-group mb-3">
          <span class="input-group-text" id="basic-user">First Name</span>
          <input type="text" class="form-control" placeholder="First name" aria-label="New account setup" aria-describedby="basic-addon2" value={firstName} onChange={e=>setFirstName(e.target.value)}/>
        </div>
        <div class="input-group mb-3">
          <span class="input-group-text" id="basic-user">Last Name</span>
          <input type="text" class="form-control" placeholder="Last name" aria-label="New account setup" aria-describedby="basic-addon2" value={lastName} onChange={e=>setLastName(e.target.value)}/>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSubmit}>Submit</Button>
        <Button variant="secondary" onClick={onHide}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  )
}
