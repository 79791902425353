import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { dvSrv } from "./App";
import axios from "axios";
import ReactJson from "@microlink/react-json-view";
import { SignIn } from "./Signin";

export function SignUp() {
  const location = useLocation();
  
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const invite_username = queryParams.get('username');
  const invite_accountID = parseInt(queryParams.get('accountID'));
  
  const [username, setUsername] = useState(invite_username)
  const [password, setPassword] = useState()
  const [accountID, setAccountID] = useState(invite_accountID)
  const [code, setCode] = useState()
  const [userMessage, setUserMessage] = useState()
  const [errBar, setErrBar] = useState("")
  const [accounts, setAccounts] = useState([
    {ID: 1, Name: "MedStar"},
    {ID: 2, Name: "Johnson County"},
  ])

  // useEffect(() => {
  //   fetch(dvSrv + "/api/account/")
  //   .then(response => response.json())
  //   .then(setAccounts)
  //   .catch(error => console.error("Error loading accounts: ", error))
  // }, [])

  async function handleSigUp() {
    setErrBar("Please wait");
    axios.post(dvSrv + "/api/signup", {
      username: username,
      password: password,
      AccountID: accountID,
      Code: code,
      Token: token,
    }, {withCredentials: true})
    .then(({data}) => {
      // setUserMessage(data);
      // console.log(response.data);
    })
    .catch((error) => {
      console.error("Error sign up: ", error.response.data);
      if (error.response) {
        setErrBar(error.response.data.error);
      }
    });
  }

  const onChange = (e) => {
    const {name, value} = e.target
    e.persist()

    switch (name) {
      case "floatingUsername": 
        setUsername(value); break
      case "floatingPassword":
        setPassword(value); break
      case "accountID":
        setAccountID(parseInt(value)); break
      case "floatingCode":
        setCode(value); break
    }
  }

  return (
    <>
    <div class="form-signin w-100 m-auto">
      <h1 class="h3 mb-3 fw-normal">Sign up</h1>

      <div class="form-floating">
        <input type="email" class="form-control" name="floatingUsername" placeholder="name@example.com" value={username} onChange={onChange} />
        <label for="floatingUsername">Email address</label>
      </div>
      <div class="form-floating">
        <input type="password" class="form-control" name ="floatingPassword" placeholder="Password" value={password} onChange={onChange} />
        <label for="floatingPassword">Password</label>
      </div>
      <div class="form-floating">
        <input type="text" class="form-control" name="floatingCode" placeholder="Code" value={code} onChange={onChange} />
        <label for="floatingCode">Invitation code?</label>
      </div>

      {/* <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">Username</span>
        </div>
        <input name='username' type="text" class="form-control" 
        value={username} onChange={onChange} placeholder="Username" />
      </div>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">Password</span>
        </div>
        <input name='password' type="password" class="form-control" 
        value={password} onChange={onChange} placeholder="Password" />
      </div> */}

      <div class="input-group mt-1">
        {/* <div class="input-group-prepend">
          <span class="input-group-text">Account</span>
        </div> */}
        <select class="form-select" aria-label="Account select"
          name="accountID" value={accountID}
          onChange={onChange}>
          <option selected>Choose an account, or call us.</option>
          {accounts && accounts.map(account => 
            <option value={account.ID}>{account.Name}</option>
          )}
        </select>
      </div>

      {/* <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">Code</span>
        </div>
        <input name='code' type="text" class="form-control" 
        value={code} onChange={onChange} placeholder="Has a code?" />
      </div> */}

      {/* <button class="btn btn-warning w-100 mt-3 py-2" onClick={handleSigUp}>Sign Up</button> */}

      <p>{errBar && errBar}</p>

      </div>
      {/* <ReactJson src={{username, password, accountID, code, accounts, userMessage, token}} collapsed="2" /> */}
    </>
  )

  const HasSignedIn = () => {
    <>
      <h2>You've signed in.</h2>
      <h3>Sign in as another user?</h3>
    </>
  }

  // return (
  //   isSignedIn
  //     ? <HasSignedIn /> 
  //     : <SignInForm />
  // )
}

export function VerifyEmail() {
  const navigate = useNavigate()
  const location = useLocation()  
  const queryParams = new URLSearchParams(location.search)
  const token = queryParams.get('token')
  const accountID = queryParams.get('accountID')
  const [verified, setVerified] = useState(false)

  useEffect(() => {
    axios.get(dvSrv+`/api/email/verify?token=${token}&accountID=${accountID}`)
    .then(data => {
      if (!verified) {
        //alert("Email verified successfully, redirecting to Signin page!");
        setVerified(true)
        setTimeout(() => { navigate('/signin') }, 2000); // pause for 2 second
      }
    })
    .catch(error => {
      console.error("Error verifying email: ", error);
      navigate("/")
    })
  }, [token])

  return (
    <>
    {verified
    ? <h5>Email verified! Redirecting to sign in page ... </h5>
    : <h5>Verifying email ...</h5>
    }
    </>
  )
}
