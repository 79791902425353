import React, { useEffect } from 'react';
import { useRemark } from 'react-remark'
import rehypeAutolinkHeadings from 'rehype-autolink-headings'
import rehypeSlug from 'rehype-slug'

export const MarkdownWithAnchors = ({ markdownContent }) => {

  const [processedMarkdown, setProcessedMarkdown] = useRemark({
    //    remarkToRehypeOptions: { allowDangerousHtml: true },
    rehypePlugins: [rehypeSlug, rehypeAutolinkHeadings],
    //  rehypeReactOptions: {
    //    components: {
    //      p: (props) => <p className="custom-paragraph" {...props} />,
    //    },
    // },
  });

  useEffect(() => {
    setProcessedMarkdown(markdownContent)
  }, [markdownContent]);

  return (
   // <div dangerouslySetInnerHTML={{ __html: addIdsToHeadings(processedMarkdown) }} />
   <div> {processedMarkdown} </div>
  );
}
