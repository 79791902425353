import { Sidebar } from "./Sidebar";
import { NaviHeader } from "./Navigation";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify"

/* A "layout route" is a good place to put markup you want to
  share across all the pages on your site, like navigation. */
export const MainLayout = () => {
  return (
    <>
    <NaviHeader />
    <div class="wrapper">
      <Sidebar />
      <main>      
        <ToastContainer hideProgressBar={true} draggable={false} />
        <Outlet />
        <Footer />
      </main>
    </div>
    </>
  )
}

const Footer = () => {
  return (
    <>
    <div class="pt-4 pb-2 text-center">
      &copy; 2024 Deepvin, Inc. All rights reserved.
    </div>
    <div class="pb-4 text-center">
      <a href="/disclaimer">Disclaimer</a> &nbsp;&nbsp;<a href="/privacy">Privacy Statement</a>
    </div>
    </>
  )
}