import styled from "styled-components";
import { Link } from "react-router-dom";
import { SignOutButton } from "./Navigation";
import { useContext } from "react";
import { SessionContext } from "../App";

export const Sidebar = () => {

  const {data} = useContext(SessionContext)

  // https://fontawesome.com/v4/cheatsheet/
  const shareRoutes = [
    { name: "Resources"},
    { path: "/tutorial", name: "Tutorial", ico: "\f02d" },
  ]
  
  const userRoutes = [
    { name: "My data"},
    { path: "/dashboard", name: "Dashboard", ico: "\f0e4" },
    { path: "/video/", name: "My Videos", ico: "\f03d" },
    { path: "/task/", name: "My Tasks", ico: "\f0ae" },
    { path: "/upload", name: "Upload", ico: "\f093" },
  ]  
  
  const teamAdminRoutes = [
    // { path: "/dashboard/", name: "Dashboard", exact: true, ico: "\f00a" },
    { name: "My data"},
    { path: "/dashboard", name: "Dashboard", ico: "\f0e4" },
    { path: "/team/", name: "My Team", ico: "\f0f9" },
    { path: "/video/", name: "My Videos", ico: "\f03d" },
    { path: "/task/", name: "Tasks", ico: "\f0ae" },
    { path: "/upload", name: "Upload", ico: "\f093" },
    // { path: "/video/", name: "Favorites", ico: "\f005" },
    // { name: ""},
    // { name: "My Tasks"},
    // { path: "/video/drafted", name: "Drafts", ico: "\f044" },
    // { path: "/video/", name: "To Respond", ico: "\f01c" },
    // { name: ""},
  ]

  const rootRoutes = [
    { name: "Admin"},
    { path: "/dashboard", name: "Dashboard", ico: "\f0e4" },
    { path: "/team/admin", name: "Admin Panel", ico: "\f0e8" },
    { path: "/report/", name: "Reports", ico: "\f080" },
    { path: "/user/", name: "All Users", ico: "\f0c0" },
    { path: "/setting/", name: "Settings", ico: "\f085" },
    { path: "/task/", name: "Tasks", ico: "\f0ae" },
    { path: "/upload", name: "Upload", ico: "\f093" },
    // { path: "/", name: "Analysis", ico: "\f024" },
    // { path: "/", name: "Invoice", ico: "\f01c" },
    // { path: "/", name: "Orders", ico: "\f005" },  
  ]
   
  const deepvinRoutes = [
    { name: "DEEPVIN"},
    { path: "/account/", name: "All Accounts", ico: "\f015" },
    // { path: "/", name: "Users", ico: "\f005" },
  ]
  
  const routes = data && data.IsRoot
    ? data.AccountID === 1 
      ? [...deepvinRoutes, ...rootRoutes, ...shareRoutes]
      : [...rootRoutes, ...shareRoutes]
    : data && data.IsAdmin 
      ? [...teamAdminRoutes, ...shareRoutes]
      : [...userRoutes, ...shareRoutes]
      
  const StyledLink = styled(Link)`
    &:before { 
      font: normal 18px fontawesome;
      top: 14px;
      left: -2px;
      content: '${props => props.ico}'; }
  `

  return (
    <>
    {/* <div class="side-navbar active-nav d-flex justify-content-between flex-wrap flex-column" id="sidebar">
  <ul class="nav flex-column text-white w-100">
    <a href="#" class="nav-link h3 text-white my-2">
      Side Nav
    </a>
    <li href="#" class="nav-link">
      <span class="mx-2">Home</span>
    </li>
    <li href="#" class="nav-link">
      <span class="mx-2">About</span>
    </li>
    <li href="#" class="nav-link">
      <span class="mx-2">Contact</span>
    </li>
  </ul>
</div> */}
  <nav>
    {/* <div class="nav side-navbar active-nav"> */}
      <ul>
        {routes.map( route => route.path
          ? <li>
              <StyledLink ico={route.ico} exact={route.exact} to={route.path}>
              {route.name}
              </StyledLink>
            </li>
          : <li><span>{route.name}</span></li>
        )}
      </ul>
    {/* </div> */}
    </nav>
</>
  )
}
// return 
// <div class="sidebar" role="cdb-sidebar" color="white">
//   <div class="sidebar-container">
//     <div class="sidebar-header">
//       <a class="sidebar-brand">Contrast Light</a>
//       <a class="sidebar-toggler"><i class="fa fa-bars"></i></a>
//     </div>
//     <div class="sidebar-nav">
//       <div class="sidenav">
//         <a class="sidebar-item">
//           <div class="sidebar-item-content">
//             <i class="fa fa-th-large sidebar-icon sidebar-icon-lg"></i>
//             <span>Dashboard</span>
//             <div class="suffix">
//               <div class="badge rounded-pill bg-danger">new</div>
//             </div>
//           </div>
//         </a>
//         <a class="sidebar-item">
//           <div class="sidebar-item-content">
//             <i class="fa fa-sticky-note sidebar-icon"></i>
//             <span>Components</span>
//           </div>
//         </a>
//         <a class="sidebar-item">
//           <div class="sidebar-item-content">
//             <i class="fa fa-sticky-note sidebar-icon"></i>
//             <span>Bootstrap</span>
//           </div>
//         </a>
//       </div>
//       <div class="sidebar-footer">Sidebar Footer</div>
//     </div>
//   </div>
// </div>
// }
    