import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import { useParams } from "react-router-dom"
import { SessionContext } from '../App';
import { dvSrv } from '../App';
import { Notes } from './Note';
import { AirwayReport, CaseReport } from './Report';
import { Players } from './Player';
import ReactJson from '@microlink/react-json-view';
import { UserNotifyModal } from "./User"
import { useTeamAndUser } from "../hook/DataProvider"

export const Case = () => {

  const session = useContext(SessionContext)
  const {id} = useParams() // Video CaseNumber
  const [currentCN, setCurrentCN] = useState(id)
  const [editedCN, setEditedCN] = useState(id)

  const [videos, setVideos] = useState([])

  const [activeVid, setActiveVid] = useState();
  const [playingStatus, setCasePlayingStatus] = useState(true);

  //// player relate vars
  const playerRefs = useRef({});
  const [showNotifyModal, setShowNotifyModal] = useState(false)
  const [teams, users] = useTeamAndUser()
  const [editCN, setEditCN] = useState(false)

  useEffect(() => {
    axios.get(dvSrv + `/api/case/${currentCN}`, {withCredentials: true})
    .then(({data}) => {
      setVideos(data.Videos)
      if (data.Videos.length > 0)
        setActiveVid(data.Videos[0].ID)

      const refs = []
      data.Videos.forEach(v => {
        refs[v.ID] = React.createRef()
      })
      playerRefs.current = refs
      
    })
    .catch(error => {
      console.log('Error:', error);
      if (error.response && error.response.status === 401) {
          session.setData(null)
      } else {console.error("Error: ", error)}
    })}, [currentCN])
    
    const handleCancelEditCN = (e) => {
      setEditedCN(currentCN)
      setEditCN(false)
    }
    
    const handleCNEditClick = (e) => {
      if (editCN){ // save CN change
        // Trim the input value to remove leading and trailing white spaces
        const trimmedInput = editedCN.trim();
        // Check if the trimmed input is empty
        if (trimmedInput === "") {
            setEditedCN(currentCN)
            alert("The input cannot be empty or just white spaces.")
            return
        }

        axios.post(dvSrv + `/api/case/${currentCN}`, 
        {
          NewCaseNum: trimmedInput
        },
        {withCredentials: true})
        .then(({data}) => {
          //setVideos(data)
          setCurrentCN(trimmedInput)
          setEditedCN(trimmedInput)
        })
        .catch(error => {
          console.log('Error:', error);
          if (error.response && error.response.status === 401) {
              session.setData(null)
          } else {console.error("Error: ", error)}
        })
      }

      setEditCN(!editCN)
    }

  if (videos && activeVid)
  return (
    <>
    <div class="row">
      <div class="col-6">
        <div class="row">
          <div class="col-lg-8 col-sm-8">
            <div class="input-group align-items-center">
              <span class="input-group-text border-0 fw-bold" id="basic-cn">Case # </span>&nbsp;
              {editCN 
                ? <input type="text" class="form-control" placeholder="CaseNumber" aria-label="casenumber" value={editedCN} onChange={e=>setEditedCN(e.target.value)}/>
                :  currentCN
              }
            </div>
            
            {/* <h4>Case #
              {editCN
              ? <input type="text" class="form-control" value={editedCN} placeholder="CaseNumber" aria-label="casenumber" onChange={e=>setEditedCN(e.target.value)}/>
              : currentCN
              }
            </h4> */}
          </div>
          <div class="col-lg-4 col-sm-4">
          {editCN
            ? <div class="btn-group align-items-center" role="group">
                <button class="btn btn-success" onClick={handleCNEditClick}>Save</button>
                <button class="btn btn-warning" onClick={handleCancelEditCN}>Cancel</button>
            </div>
            : <div class="btn-group align-items-center" role="group">
                <button class="btn btn-outline-secondary" onClick={handleCNEditClick}>Edit</button>
                <button class="btn btn-outline-secondary" onClick={()=>setShowNotifyModal(true)}>Notify</button>
            </div>
            }
          </div>
        </div>
        {<Players videos={videos} playerRefs={playerRefs.current} setActiveVid={setActiveVid}  playingStatus={playingStatus} setCasePlayingStatus={setCasePlayingStatus}/>}
      </div>
      <div class="col-6">
        <CaseReview caseNumber={currentCN} video={videos.find(v => v.ID === activeVid)} playerRef={playerRefs.current[activeVid]} setCasePlayingStatus={setCasePlayingStatus}/>
      </div>
    </div>
    { users && <UserNotifyModal show={showNotifyModal} setShow={setShowNotifyModal} caseNum={currentCN} />}
    {/* <ReactJson src={videos} /> */}
    </>
  )
}

export const CaseReview = ({caseNumber, video, playerRef, setCasePlayingStatus}) =>{
  return (
    <>
    <div class="accordion" id="reportAndNotes">
      <div class="accordion-item">
        <h2 class="accordion-header custom-accordion-button " id="headingNewCard">
          <button class="accordion-button d-flex justify-content-between" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNewCard" aria-expanded="true" aria-controls="collapseNewCard">
            <p className="accordion-right-align"><strong>Intubation Report Card </strong></p>               
          </button>
        </h2>
        <div id="collapseNewCard" class="accordion-collapse collapse show" aria-labelledby="headingNewCard" data-bs-parent="#reportAndNotes">
          <div class="accordion-body"> 
            <div class="align-items-top">
                {video && <CaseReport caseNumber={caseNumber} />}
            </div>
          </div>
        </div>
      </div>
      {/* <div class="accordion-item">
        <h2 class="accordion-header custom-accordion-button " id="headingCard">
          <button class="accordion-button d-flex justify-content-between" type="button" data-bs-toggle="collapse" data-bs-target="#collapseCard" aria-expanded="true" aria-controls="collapseCard">
            <p className="accordion-right-align"><strong>Airway Report Card</strong></p>               
          </button>
        </h2>
        <div id="collapseCard" class="accordion-collapse collapse" aria-labelledby="headingCard" data-bs-parent="#reportAndNotes">
          <div class="accordion-body"> 
            <div class="align-items-top">
                {video && <AirwayReport caseNumber={video.CaseNumber} />}
            </div>
          </div>
        </div>
      </div> */}

      <div class="accordion-item">
        <h2 class="accordion-header" id="headingThree">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
          <p className="accordion-right-align"><strong>Video Notes</strong></p>
          </button>
        </h2>
        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#reportAndNotes">
          <div class="accordion-body">
            <div>
              {video && <Notes video={video} playerRef={playerRef} setCasePlayingStatus={setCasePlayingStatus}/>}
            </div>
          </div>
        </div>
      </div>
    </div>
        {/* <hr /> 
        <div class="col-6">
          <button type="button" class="btn btn-danger me-2" onClick={(e) => handleUpdate(e, true)}>Submit</button>
        </div> */}
    {/* <ReactJson src={{url, title, video}} /> */}
    </>
  )
}